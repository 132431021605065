import React, { useEffect, useState } from 'react';
import { Menu } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import FilterPanel from '../common/FilterPanel';
import CheckboxFilter from '../common/CheckboxFilter';
import { MARKETPLACE_DATA } from '../../utils/ConnectionConfig';

type Option = {
  label: string;
  value: string;
  [key: string]: any; // Allows for additional properties
};

interface ParsedFilters {
  merchant: string[];
  marketplace: string[];
  status: string[];
}
interface ProductFilterProps {
  merchants: Option[];
  marketplaces: Option[];
  statuses: Option[];
  onApply: (merchants: Option[], statuses: Option[], marketplaces: Option[]) => void;
  onReset: () => void;
}

const ProductFilter: React.FC<ProductFilterProps> = ({
  merchants,
  marketplaces,
  statuses,
  onApply,
  onReset,
}) => {
  const [selectedMerchants, setSelectedMerchants] = useState<Option[]>([]);
  const [selectedMarketplaces, setSelectedMarketplace] = useState<Option[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<Option[]>([]);

  useEffect(() => {
    // Load initial filters from URL
    const urlFilters = parseFiltersFromUrl();
    console.log(urlFilters);
    if (Object.keys(urlFilters).length > 0) {
      const parsedMerchants = urlFilters.merchant
        .map((merchantId) => merchants.find((merchant) => merchant.value === merchantId))
        .filter((merchant): merchant is Option => merchant !== undefined);
      setSelectedMerchants(parsedMerchants);

      const parsedStatus = urlFilters.status.map((status) => {
        return statuses.find((s) => s.value === status);
      });
      setSelectedStatuses(parsedStatus);

      const parsedMarketplace = urlFilters.marketplace.map((marketplace) => {
        const marketplaceData = MARKETPLACE_DATA.find((m) => marketplace === m.marketplaceId);
        return {
          ...marketplaceData,
          label: marketplaceData.country,
          value: marketplaceData.marketplaceId,
        };
      });

      onApply(parsedMerchants, parsedStatus, parsedMarketplace);
    }
  }, []);

  const parseFiltersFromUrl = (): ParsedFilters => {
    const searchParams = new URLSearchParams(window.location.search);
    const filters: ParsedFilters = {
      merchant: [],
      marketplace: [],
      status: [],
    };
    searchParams.forEach((value, key) => {
      if ((key === 'merchant' || key === 'status' || key === 'marketplace') && value) {
        filters[key].push(value);
      }
    });
    return filters;
  };

  return (
    <Menu as='div' className='relative overflow-visible'>
      {({ close }) => (
        <>
          <Menu.Button className='relative h-10 w-10 rounded-md border border-gray-300 p-1 text-gray-400 hover:text-gray-800 shadow-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
            <FunnelIcon className='h-6 w-6 m-auto' aria-hidden='true' />
          </Menu.Button>

          <Menu.Items className='absolute w-[300px] z-50 overflow-visible top-12 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
            <FilterPanel
              onApplyFilters={() => {
                onApply(selectedMerchants, selectedStatuses, selectedMarketplaces);
                close();
              }}
              onCancel={() => {
                close();
              }}
              handleResetFilters={() => {
                setSelectedMerchants([]);
                setSelectedStatuses([]);
                setSelectedMarketplace([]);
                onReset();
              }}
              classNames={{
                panel: 'p-4',
                header: 'mb-4',
                title: 'text-[20px]',
                clearButton: 'text-red-600 hover:opacity-60 text-[14px]',
                cancelButton:
                  'rounded-md bg-white text-hopstack-blue-700 border border-hopstack-blue-700 px-8 py-2 text-sm font-semibold shadow-sm hover:bg-gray-50',
                confirmButton:
                  'rounded-md bg-hopstack-blue-700 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400',
              }}
            >
              {merchants?.length > 0 && (
                <CheckboxFilter
                  id='merchant'
                  label='Select Clients'
                  options={merchants}
                  selectedOptions={selectedMerchants}
                  onChange={setSelectedMerchants}
                />
              )}

              {marketplaces?.length > 0 && (
                <CheckboxFilter
                  id='marketplace'
                  label='Select Marketplaces'
                  options={marketplaces}
                  selectedOptions={selectedMarketplaces}
                  onChange={setSelectedMarketplace}
                />
              )}

              {statuses?.length > 0 && (
                <CheckboxFilter
                  id='status'
                  label='Select Status'
                  options={statuses}
                  selectedOptions={selectedStatuses}
                  onChange={setSelectedStatuses}
                />
              )}
            </FilterPanel>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

export default ProductFilter;
