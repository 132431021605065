// UploadFile.tsx

import React, { useContext, useRef } from 'react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { Warehouse } from '../common/types';
import BaseContext from '../common/BaseContext';
import { ColumnHeader } from '../../pages/Inventory';
import Papa from 'papaparse';

enum UploadStage {
  UploadFile = 'uploadFile',
  MapFields = 'mapFields',
  Confirmation = 'confirmation',
}
interface UploadFileProps {
  setSelectedFile: (file: File) => void;
  setUploadStage: (stage: UploadStage) => void;
  setOpenNewUploadSlideOver: (open: boolean) => void;
  warehouse: Warehouse;
  setS3Url: (url: string) => void;
  setFileName: (name: string) => void;
  setUploading: (uploading: boolean) => void;
  setFileHeaders: (headers: string[]) => void;
  setTotalEntries: (entries: number) => void;
  setMappedFields: (fields: Record<string, { name: string; value: string }>) => void;
  authenticatedFetch: (input: RequestInfo, init?: RequestInit) => Promise<any>;
  selectedFile: File | null;
  s3Url: string | undefined;
  uploading: boolean;
}

/**
 * Handles the file upload process for the Inventory page.
 *
 * This component manages the file upload flow, including:
 * - Selecting a file
 * - Uploading the file to an S3 bucket
 * - Processing the uploaded file (e.g. parsing CSV data)
 * - Displaying the file upload status and any errors that occur
 *
 * The component uses the `authenticatedFetch` function to obtain a pre-signed URL for uploading the file to S3,
 * and then uses the `fetch` API to upload the file directly to S3. After the file is uploaded successfully,
 * the component processes the file contents (e.g. parsing a CSV file) and updates the component state accordingly.
 */
const UploadFile: React.FC<UploadFileProps> = ({
  setSelectedFile,
  setUploadStage,
  setOpenNewUploadSlideOver,
  warehouse,
  setS3Url,
  setFileName,
  setUploading,
  setFileHeaders,
  setTotalEntries,
  setMappedFields,
  authenticatedFetch,
  selectedFile,
  s3Url,
  uploading,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { setShowNotification, setLoading } = useContext(BaseContext);

  const handleFileChange = async (file: File) => {
    setSelectedFile(file);
    try {
      const res = await authenticatedFetch(`/api/v1/inventory/pre-signed-url`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          warehouseId: warehouse.warehouseId,
        }),
      });

      if (res.success) {
        setS3Url(res.data.publicUrl);
        setFileName(res.data.fileName);
      }
    } catch (error) {
      console.error(error);
      setShowNotification({
        show: true,
        type: 'failure',
        content: 'File upload failed. Please try again later',
      });
      setSelectedFile(null);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile || !s3Url) return;

    setUploading(true);
    try {
      const fileBuffer = await selectedFile.arrayBuffer();
      const response = await fetch(s3Url, {
        method: 'PUT',
        body: fileBuffer,
        headers: {
          'Content-Type': 'text/plain',
          'Content-Length': fileBuffer.byteLength.toString(),
        },
      });

      if (response.ok) {
        setShowNotification({
          show: true,
          type: 'success',
          content: 'File uploaded successfully!',
        });
        setUploading(false);
        await processFile();
        setUploadStage(UploadStage.MapFields);
      } else {
        setShowNotification({
          show: true,
          type: 'failure',
          content: 'File upload failed',
        });
        setUploading(false);
      }
    } catch (error) {
      console.error(error);
      setShowNotification({
        show: true,
        type: 'failure',
        content: 'File upload failed. Please try again later',
      });
      setUploading(false);
    }
  };

  const processFile = async () => {
    if (!selectedFile) return;

    try {
      setLoading(true);

      if (!selectedFile.name.endsWith('.csv')) {
        setShowNotification({
          show: true,
          type: 'failure',
          content: 'Please select a CSV file',
        });
        setLoading(false);
        return;
      }

      const results: any = await new Promise((resolve, reject) => {
        Papa.parse(selectedFile, {
          complete: resolve,
          error: reject,
        });
      });

      const data = results.data.filter((row) => row.length > 2);
      const headers = data[0];
      const totalEntries = data.length - 1;

      setTotalEntries(totalEntries);
      setFileHeaders(headers);

      const mappedFields = headers.reduce((acc, header) => {
        const mapping = ColumnHeader.find((ch) => ch.name === header);
        if (mapping) {
          acc[mapping.accessor] = {
            name: mapping.accessor,
            value: mapping.name,
          };
        }
        return acc;
      }, {});

      setMappedFields(mappedFields);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowNotification({
        show: true,
        type: 'failure',
        content: 'An error occurred while processing the file.',
      });
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file && file.type === 'text/csv') {
      handleFileChange(file);
    } else {
      setShowNotification({
        show: true,
        type: 'failure',
        content: 'Invalid file.',
      });
    }
  };

  return (
    <div className='max-w-3xl mx-auto p-4'>
      <h2 className='text-2xl font-semibold mb-4'>Upload File</h2>
      <p className='mb-4 text-gray-500'>
        Please choose the file containing the inventory information that you would like to upload.
        You can also download a sample file from{' '}
        <a
          href='https://hopstack-pub.s3.amazonaws.com/ignite/BulkAdjustInventorySample.csv'
          className='text-blue-500 underline'
        >
          here
        </a>
        .
      </p>

      {/* File upload input */}
      <div
        className='border-2 border-dashed rounded-md border-gray-300 p-4 bg-gray-100 text-center mb-4'
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className='flex flex-col items-center'>
          <div className='w-10 h-10 mb-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke='#224E73'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
              />
            </svg>
          </div>
          <span className='text-gray-600'>
            Drag &amp; drop files or{' '}
            <label htmlFor='file-input' className='text-hopstack-blue-700 underline cursor-pointer'>
              Browse
            </label>
            <br />
            <span className='text-gray-400'>
              Supported format: <span>CSV</span>
            </span>
          </span>
        </div>
        <input
          ref={fileInputRef}
          id='file-input'
          type='file'
          accept='.csv'
          onChange={(e) => handleFileChange(e.target.files[0])}
          className='hidden'
        />
      </div>
      {/* Display selected file name */}
      {selectedFile && (
        <div className='mb-4 flex flex-col justify-center items-center'>
          <p className='mb-1'>
            {(uploading && (uploading ? 'Uploading' : 'Uploaded') + ' File') ||
              (selectedFile && 'Selected ') + 'File '}
          </p>
          <div className='flex items-center gap-4'>
            <p className='text-gray-600'>{selectedFile.name}</p>
            <button
              className='rounded-full w-5 h-5 bg-gray-200 text-xs font-medium hover:bg-gray-300 focus:outline-none text-white'
              onClick={() => setSelectedFile(null)}
            >
              X
            </button>
          </div>
        </div>
      )}

      {/* Back and Continue buttons */}
      <div className='flex gap-4'>
        <button
          className='px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
          onClick={() => setOpenNewUploadSlideOver(false)}
        >
          Go back
        </button>
        <button
          className={`px-6 flex py-3 text-base font-medium text-white bg-[#224E73] rounded-md hover:bg-[#224f73c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#224E73] ${
            !selectedFile && 'cursor-not-allowed opacity-50'
          }`}
          onClick={handleFileUpload}
          disabled={!selectedFile}
        >
          <div className=''>Continue</div>
          <ArrowRightIcon className='w-4 h-4 my-auto ml-2' />
        </button>
      </div>
    </div>
  );
};

export default UploadFile;
