import React, { useState } from 'react';
import SlideOver from '../common/SlideOver';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Client } from '../common/types';
import AmazonIntegration from './AmazonIntegration';
import ShopifyIntegration from './ShopifyIntegration';

type CredentialInput = {
  type: 'text' | 'password';
  label: string;
  placeholder?: string;
};

type Credentials = CredentialInput[];

export interface IntegrationConfig {
  id: string;
  name: string;
  icon: string;
  authUrl?: string;
  credentials?: Credentials;
}

const integrationConfigs: IntegrationConfig[] = [
  {
    id: 'amazon',
    name: 'Amazon Seller Central (FBA)',
    icon: 'https://assets.aboutamazon.com/88/05/0feec6ff47bab443d2c82944bb09/amazon-logo.png',
    authUrl: `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.2fa956a1-7cb7-48e4-9137-f8f11a3ea610&state=stateexample&redirect_uri=${process.env.REACT_APP_BASE_URL}/oauth/sp-api`,
  },
  {
    id: 'shopify',
    name: 'Shopify',
    icon: 'https://cdn.shopify.com/shopifycloud/brochure/assets/brand-assets/shopify-logo-primary-logo-456baa801ee66a0a435671082365958316831c9960c480451dd0330bcdae304f.svg',
    credentials: [
      {
        type: 'text',
        label: 'Shopify Store name',
        placeholder: 'Enter Store name',
      },
    ],
  },
  // Add more integration configurations here
];

interface AddIntegrationProps {
  isIntegrationsModalOpen: boolean;
  setIsIntegrationsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  merchants: Client[];
  fetchIntegrations: (page: number) => Promise<void>;
}

interface IntegrationComponentProps {
  selectedIntegration: IntegrationConfig;
  setSelectedIntegration: React.Dispatch<React.SetStateAction<IntegrationConfig | undefined>>;
  merchants: Client[];
  setIsIntegrationsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchIntegrations: (page: number) => Promise<void>;
}
const AddIntegration: React.FC<AddIntegrationProps> = ({
  isIntegrationsModalOpen,
  setIsIntegrationsModalOpen,
  merchants,
  fetchIntegrations,
}) => {
  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationConfig | undefined>();

  const getIntegrationComponent = ({
    selectedIntegration,
    setSelectedIntegration,
    merchants,
    setIsIntegrationsModalOpen,
  }: IntegrationComponentProps) => {
    switch (selectedIntegration.id) {
      case 'amazon':
        return (
          <AmazonIntegration
            selectedIntegration={selectedIntegration}
            setSelectedIntegration={setSelectedIntegration}
            merchants={merchants}
          />
        );
      case 'shopify':
        return (
          <ShopifyIntegration
            merchants={merchants}
            selectedIntegration={selectedIntegration}
            setSelectedIntegration={setSelectedIntegration}
            setIsIntegrationsModalOpen={setIsIntegrationsModalOpen}
          />
        );
      default:
        return <div>Integration not found</div>;
    }
  };

  return (
    <SlideOver open={isIntegrationsModalOpen} setOpen={setIsIntegrationsModalOpen}>
      {selectedIntegration ? (
        getIntegrationComponent({
          merchants,
          setIsIntegrationsModalOpen,
          selectedIntegration,
          setSelectedIntegration,
          fetchIntegrations,
        })
      ) : (
        <div className='flex h-full gap-2 flex-col  '>
          <div className='flex w-full justify-between'>
            <div className='text-[18px] font-bold'>Available Connections</div>
            <button onClick={() => setIsIntegrationsModalOpen(false)}>
              <XMarkIcon className='text-gray-400 w-6 h-6' />
            </button>
          </div>

          {integrationConfigs.map((integration, index) => (
            <button
              onClick={() => setSelectedIntegration(integration)}
              key={index}
              className='p-4 w-full h-40 bg-gray-100 mt-2 rounded-xl hover:shadow-md'
            >
              <img
                width={200}
                height={200}
                className='m-auto'
                src={integration.icon}
                alt={`${integration.name} icon`}
              />
              <div>{integration.name}</div>
            </button>
          ))}
        </div>
      )}
    </SlideOver>
  );
};

export default AddIntegration;
