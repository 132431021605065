import React, { useContext, useEffect, useState } from 'react';

import useFetch from '../../hooks/useFetch';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { OnboardingStep } from '../../pages/Onboarding';
import { Address, Organization, Warehouse } from '../common/types';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import BaseContext from '../common/BaseContext';
import { countries } from '../common/utils';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { SignOutButton } from '@clerk/clerk-react';
import { allCountries, CountryData } from 'country-region-data';
import useAddressValidator from '../../hooks/useAddressValidator';
import Modal from '../common/Modal';
import SearchableListbox from '../common/SearchableListbox';
interface CreateWarehouseProps {
  orgData?: Organization;
  warehouse?: Warehouse;
  setSelectedWarehouse?: any;
  setOnboardingStep?: any;
  setWarehouseStepStatus?: any;
  allowUsingOrgData: boolean;
}
export default function CreateWarehouse(props: CreateWarehouseProps) {
  const [mobileNumber, setMobileNumber] = useState({
    number: '',
    countryCode: '',
  });

  const {
    reset,
    resetField,
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    setError,
  } = useForm<Warehouse>({});

  const [addressValidationError, setAddressValidationError] = useState<string | null>(null);
  const [isAddressConfirmationModalOpen, setIsAddressConfirmationModalOpen] =
    useState<boolean>(false);
  const [correctedAddress, setCorrectedAddress] = useState<Address | null>(null);
  const formData = watch();
  const orgData: Organization = props.orgData;
  const warehouse: Warehouse = props.warehouse;
  const editMode = warehouse !== undefined;
  const { setShowNotification, setLoading } = useContext(BaseContext);
  const validateAddress = useAddressValidator();

  const [sameAddress, setSameAddress] = useState<boolean>(props.allowUsingOrgData);
  const [sameContact, setSameContact] = useState<boolean>(props.allowUsingOrgData);

  const isUS = formData?.shippingAddress?.country?.code === 'US';

  const authenticatedFetch = useFetch();

  const [selectedCountry,setSelectedCountry] = useState("United States");
  const [regionsOfSelectedCountry,setRegionsOfSelectedCountry] =useState([]);
  const [selectedRegion , setSelectedRegion] = useState(null);
    useEffect(()=>
    { 
      if (formData && formData.shippingAddress && formData.shippingAddress.country) {
  
        // Only set the selected country if the name is defined
        if (formData.shippingAddress.country.name) {
          setSelectedCountry(formData.shippingAddress.country.name);
          setSelectedRegion(formData.shippingAddress.state);
        }

        if (formData.shippingAddress.country.name=="United States")
        {
          setValue('shippingAddress.country', {
            name: "United States",
            code: "US",
          })
        }
      }
      },[formData]);

    function getRegionsByCountry(countryName: string): { name: string; abbreviation: string }[] | undefined {
      // Find the country data based on the country name
      const countryData: CountryData | undefined = allCountries.find(
          (country) => country[0] === countryName
      );
  
      // If country data is found, return the list of regions (states) with names and abbreviations
      if (countryData) {
          const regions = countryData[2]; // regions are the third element in the tuple
          return regions.map((region) => ({
              name: region[0],         // Region name
              abbreviation: region[1], // Region abbreviation
          }));
      }
  
      // If country data is not found, return undefined
      return undefined;
  }
  
  
  useEffect(() => {

    const regions=getRegionsByCountry(selectedCountry);
    setRegionsOfSelectedCountry(regions);


  },[selectedCountry]);

  useEffect(() => {
    if (orgData && props.allowUsingOrgData) {
      reset({ email: orgData.email, shippingAddress: orgData.billingAddress });
      setMobileNumber({
        countryCode: orgData.mobileNumber.countryCode,
        number: orgData.mobileNumber.number,
      });
    }
    if (warehouse) {
      reset({
        ...warehouse,
        shippingAddress: {
          ...warehouse.shippingAddress,
          zipCode: `${warehouse.shippingAddress.zipCode}${
            (warehouse.shippingAddress.zipCodeSuffix &&
              `-${warehouse.shippingAddress.zipCodeSuffix}`) ||
            ''
          }`,
        },
      });
      setMobileNumber({
        countryCode: warehouse.mobileNumber.countryCode,
        number: warehouse.mobileNumber.number,
      });
    }
  }, [orgData, props.allowUsingOrgData, warehouse]);

  function createWarehouse(warehouse: Warehouse) {
    if (warehouse.warehouseId) {
      const updatedWarehouseData = _.pick(warehouse, [
        'name',
        'email',
        'mobileNumber',
        'shippingAddress',
      ]);

      authenticatedFetch(`/api/v1/warehouses/${warehouse.warehouseId}`, {
        method: 'PUT',
        body: JSON.stringify({
          ...updatedWarehouseData,
          shippingAddress: {
            ...updatedWarehouseData.shippingAddress,
            email: updatedWarehouseData.email,
            name: updatedWarehouseData.name,
          },
        }),
      }).then((res) => {
        if (res.success === false) {
          console.log(
            res.errors && res.errors.length > 1
              ? res.errors[0].message
              : 'No error message received',
          );
        } else {
          props.setSelectedWarehouse(false);

          if (editMode) {
            setShowNotification({
              show: true,
              type: 'success',
              content: 'Warehouse updated successfully',
            });
          } else {
            setShowNotification({
              show: true,
              type: 'success',
              content: 'Warehouse created successfully',
            });
          }
        }
      });
    } else {
      authenticatedFetch('/api/v1/warehouses', {
        method: 'POST',
        body: JSON.stringify({
          ...warehouse,
          organizationId: orgData?.organizationId,
          shippingAddress: warehouse.shippingAddress,
        }),
      }).then((res) => {
        if (res.success === false) {
          console.log(
            res.errors && res.errors.length > 1
              ? res.errors[0].message
              : 'No error message received',
          );
          setShowNotification({
            show: true,
            type: 'failure',
            content: res.message || res.errors[0]?.message,
          });
        } else {
          props.setOnboardingStep(OnboardingStep.selectPlan);
          // props.setWarehouseStepStatus('complete')
          setShowNotification({
            show: true,
            type: 'success',
            content: 'Warehouse created successfully',
          });
        }
      });
    }
  }

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        const updatedAddress = {
          ...data.shippingAddress,
          zipCode: data.shippingAddress.zipCode.split('-')[0],
          zipCodeSuffix: data.shippingAddress.zipCode.split('-')[1],
        };
        if (!sameAddress) {
          try {
            setAddressValidationError(null);
            setLoading(true);
            const resp = await validateAddress(data.shippingAddress);

            setLoading(false);
            if (resp.suggestedAction === 'FIX') {
              setAddressValidationError('Incorrect address please fix and try again');
              return;
            } else if (resp.suggestedAction === 'ADD_SUBPREMISES') {
              setAddressValidationError('Specifiy the sub premises');
              return;
            } else if (resp.suggestedAction === 'CONFIRM') {
              setIsAddressConfirmationModalOpen(true);
              setCorrectedAddress(resp.suggestedAddress);
              return;
            }
          } catch (error) {
            setLoading(false);
            console.log(error);
          }
        }

        createWarehouse({
          ...data,
          shippingAddress: {
            ...updatedAddress,
            name: data.name,
            email: data.email,
            mobileNumber: mobileNumber,
          },
          mobileNumber: mobileNumber,
        });
      })}
    >
      <div className='space-y-12'>
        <div className='border-b border-gray-900/10 pb-12'>
          {/* <h1 className='font-bold text[20px]'>Onboarding Step 2</h1> */}

          <h2 className='text-[35px] font-semibold leading-7 text-gray-900 mt-8'>
            Warehouse Information
          </h2>
          <p className='text-[14px] w-1/2 pr-4 mt-2'>
            Please enter your warehouse details here. We will use this as the origin address while
            purchasing shipping for your outbound orders.
          </p>
        </div>

        <div className='border-b border-gray-900/10 pb-12 relative'>
          {/* <h2 className='text-base font-semibold leading-7 text-gray-900'>Personal Information</h2>
          <p className='mt-1 text-sm leading-6 text-gray-600'>
            Use a permanent address where you can receive mail.
          </p> */}

          <div className='mt-10 grid grid-cols-1 gap-x-0 gap-y-8 sm:grid-cols-12 w-[900px] px-2'>
            <div className='sm:col-span-4'>
              <label
                htmlFor='first-name'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Warehouse name*
              </label>
              <div className='mt-2'>
                <input
                  {...register('name', { required: 'Name is required' })}
                  className='block w-3/4 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                />
                {errors.name?.type === 'required' && (
                  <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                    {errors.name?.message.toString()}
                  </p>
                )}
              </div>
            </div>

            {props.allowUsingOrgData && (
              <div className='flex gap-x-3 sm:col-span-12'>
                <div className='flex h-6 items-center'>
                  <input
                    checked={sameContact}
                    onChange={(e) => {
                      setSameContact(e.target.checked);
                      if (e.target.checked) {
                        if (orgData?.organizationId) {
                          resetField('email', { defaultValue: orgData?.email });
                          setMobileNumber({
                            countryCode: orgData?.mobileNumber?.countryCode,
                            number: orgData?.mobileNumber?.number,
                          });
                        }
                      } else {
                        resetField('email', { defaultValue: '' });
                        setMobileNumber({ countryCode: '', number: '' });
                      }
                    }}
                    id='comments'
                    name='comments'
                    type='checkbox'
                    className='h-4 w-4 rounded border-gray-300 text-hopstack-blue-600 focus:ring-hopstack-blue-600'
                  />
                </div>
                <div className='text-sm leading-6'>
                  <label htmlFor='comments' className='font-medium text-gray-900'>
                    Contact details same as organization contact details
                  </label>
                </div>
              </div>
            )}

            <div className='sm:col-span-4'>
              <label
                htmlFor='last-name'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Email address
              </label>
              <div className='mt-2'>
                <input
                  disabled={sameContact}
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Entered value does not match email format',
                    },
                  })}
                  autoComplete='email'
                  className='block w-3/4 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                />
                {errors.email && (
                  <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                    {errors.email?.message.toString()}
                  </p>
                )}
              </div>
            </div>

            <div className='sm:col-span-4'>
              <label
                htmlFor='last-name'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Warehouse Phone Number
              </label>
              <PhoneInput
                disabled={sameContact}
                country={'us'}
                containerStyle={{
                  width: '100%',
                  maxWidth: '230px',
                  borderRadius: '8px',  // Consistent rounding on all corners
                  border: '1px solid #D1D5DB',
                }}
                inputStyle={{
                  width: '100%',
                  borderRadius: '8px',  // Consistent rounding on all corners
                  padding: '8px 16px',
                  paddingLeft: '45px',
                  boxSizing: 'border-box',
                }}
                enableSearch={true}
                value={`${mobileNumber?.countryCode + mobileNumber?.number}`}
                onChange={(value, data: any) => {
                  setMobileNumber({
                    countryCode: data.dialCode,
                    number: value.slice(data.dialCode.length),
                  });
                }}
              />
              {errors.mobileNumber && (
                <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                  {errors.mobileNumber?.message.toString()}
                </p>
              )}
            </div>

            {props.allowUsingOrgData && (
              <div className='flex gap-x-3 sm:col-span-6'>
                <div className='flex h-6 items-center'>
                  <input
                    checked={sameAddress}
                    onChange={(e) => {
                      setSameAddress(e.target.checked);
                      if (e.target.checked) {
                        if (orgData?.organizationId) {
                          resetField('shippingAddress', { defaultValue: orgData?.billingAddress });
                        }
                      } else {
                        resetField('shippingAddress', {
                          defaultValue: {
                            email: '',
                            line1: '',
                            line2: '',
                            city: '',
                            state: '',
                            country: {
                              code: '',
                              name: '',
                            },
                            zipCode: '',
                          },
                        });
                        // setCountry({ code: '', name: '' })
                      }
                    }}
                    id='sameAddress'
                    name='comments'
                    type='checkbox'
                    className='h-4 w-4 rounded border-gray-300 text-hopstack-blue-600 focus:ring-hopstack-blue-600'
                  />
                </div>
                <div className='text-sm leading-6'>
                  <label htmlFor='sameAddress' className='font-medium text-gray-900'>
                    Shipping address same as organization billing address
                  </label>
                </div>
              </div>
            )}
          <div className='border-b border-gray-900/10 pb-2 sm:col-span-12' />
            <div className='sm:col-span-4'>
              <label className='block text-sm font-medium leading-6 text-gray-900'>
                Shipping Address 1
              </label>
              <div className='mt-2'>
                <input
                  disabled={sameAddress}
                  {...register('shippingAddress.line1', {
                    required: 'Shipping Address Line 1 is required',
                  })}
                  className='block w-3/4 rounded-lg border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                />
                {errors.shippingAddress?.line1 && (
                  <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                    {errors.shippingAddress?.line1?.message.toString()}
                  </p>
                )}
              </div>
            </div>
            <div className='sm:col-span-6'>
              <label className='block text-sm font-medium leading-6 text-gray-900'>
                Shipping Address 2
              </label>
              <div className='mt-2'>
                <input
                  disabled={sameAddress}
                  {...register('shippingAddress.line2')}
                  className='block w-[220px] rounded-lg border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>
            <div className='sm:col-span-4'>
              <div className='hidden bg-green-400'></div>
              <label
                htmlFor='country'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Country*
              </label>
              <div className='mt-2'>
              <SearchableListbox
                onChange={(selectedOption :  {value : string, label : string}) => {
                  setSelectedCountry(selectedOption.value);
                  setError('shippingAddress.country', {
                    type: 'custom',
                    message: '',
                  });
      
                  if (selectedOption) {
                    setValue('shippingAddress.country', {
                      name: selectedOption.label,
                      code: selectedOption.value,
                    });

                  } 
                }}
                  options={countries
                    .map(country => ({
                      value: country.code,
                      label: country.name,
                    }))
                    .sort((a, b) => {
                      if (a.value === 'US' || a.value === 'CA') return -1;
                      if (b.value === 'US' || b.value === 'CA') return 1;
                      return 0;
                    })
                  }
                  classNames={{
                    control: (state) => {
                      if (state.isFocused) {
                        return ' !ring-2 !outline-hopstack-blue-600 !ring-inset !ring-hopstack-blue-600';
                      }
                    },
                  }}
                  className='w-[222px] borderRadius-[16px] rounded-lg border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                  placeholder={selectedCountry}
                />
                {errors.shippingAddress?.country && (
                  <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                    {errors.shippingAddress?.country?.message.toString()}
                  </p>
                )}
              </div>
           </div>

           <div className='sm:col-span-4'>
            <label htmlFor='region' className='block text-sm font-medium leading-6 text-gray-900'>
              State / Province*
            </label>
            <div className='mt-2'>
              {regionsOfSelectedCountry ? (
                <SearchableListbox
                  onChange={(event: { value : string; label : string }) => {
                    setValue('shippingAddress.state', event.value);
                  }}
                  options={regionsOfSelectedCountry.map((region) => ({
                    value: region.abbreviation,
                    label: region.name,
                  }))}
                  classNames={{
                    control: (state) => {
                      if (state.isFocused) {
                        return ' !ring-2 !outline-hopstack-blue-600 !ring-inset !ring-hopstack-blue-600';
                      }
                    },
                  }}
                  className='w-[222px] rounded-lg border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                  placeholder={selectedRegion}
                />
              ) : (
                <input
                  {...register('shippingAddress.state', {
                    required: 'Shipping Address State is required',
                  })}
                  className='block w-3/4 rounded-lg border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                />
              )}
              {errors.shippingAddress?.state && (
                <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                  {errors.shippingAddress?.state?.message.toString()}
                </p>
              )}
            </div>
          </div>

            <div className='sm:col-span-4 sm:col-start-1'>
              <label htmlFor='city' className='block text-sm font-medium leading-6 text-gray-900'>
                City
              </label>
              <div className='mt-2'>
                <input
                  disabled={sameAddress}
                  {...register('shippingAddress.city', {
                    required: 'Shipping Address City is required',
                  })}
                  className='block w-3/4 rounded-lg border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                />
                {errors.shippingAddress?.city && (
                  <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                    {errors.shippingAddress?.city?.message.toString()}
                  </p>
                )}
              </div>
            </div>

            <div className='sm:col-span-4'>
              <label className='block text-sm font-medium leading-6 text-gray-900'>Zip</label>
              <div className='mt-2'>
                <input
                  disabled={sameAddress}
                  {...register('shippingAddress.zipCode', {
                    required: 'Shipping Address Zip Code is required',
                  })}
                  className='block w-3/4 rounded-lg border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                />
                {errors.shippingAddress?.zipCode && (
                  <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                    {errors.shippingAddress?.zipCode?.message.toString()}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-6 flex items-center justify-start gap-x-6'>
        {addressValidationError && (
          <p className='text-red-500 text-[12px] opacity-80' role='alert'>
            {addressValidationError}
          </p>
        )}
      </div>
      <div className='mt-6 flex items-center justify-start gap-x-6'>
        <button
          onClick={() => {
            if (warehouse) props.setSelectedWarehouse(null);
            else props.setOnboardingStep(OnboardingStep.createOrganization);
          }}
          className='rounded-md bg-hopstack-blue-600 flex gap-2 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
        >
          <ArrowLeftIcon className='w-4 h-4 my-auto' />
          Go Back
        </button>

        <input
          type='submit'
          value={warehouse ? 'Save' : 'Next'}
          className='rounded-md bg-hopstack-blue-600 flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
          onClick={() => {
            clearErrors();
            if (isUS && !formData?.shippingAddress?.state) {
              setError('shippingAddress.state', { type: 'custom', message: 'State is Required' });
            }

            if (!mobileNumber || !mobileNumber?.number) {
              setError('mobileNumber', { type: 'custom', message: 'Phone Number is Required' });
            }
            if (!mobileNumber || !mobileNumber?.number) {
              setError('mobileNumber', { type: 'custom', message: 'Phone Number is Required' });
            }

            if (!formData?.shippingAddress?.country || !formData?.shippingAddress?.country?.name) {
              setError('shippingAddress.country', {
                type: 'custom',
                message: 'Country is Required',
              });
            }
          }}
        ></input>
      </div>
      <Modal isOpen={isAddressConfirmationModalOpen} setIsOpen={setIsAddressConfirmationModalOpen}>
        <div className='w-[500px]'>
          <h2 className='text-left text-[20px]'>Please confirm the updated address</h2>
          <div className='block text-sm font-medium text-left leading-6 mt-4 text-gray-900'>
            <div>
              {correctedAddress?.line1}, {correctedAddress?.line2}
            </div>
            <div>
              {correctedAddress?.city},{' '}
              {((correctedAddress?.state as any)?.name as string) || correctedAddress?.state}
            </div>
            <div>
              {correctedAddress?.country?.code} - {correctedAddress?.zipCode}
            </div>
          </div>
          <div className='flex gap-2 mt-8'>
            <button
              onClick={() => {
                const updatedWarehouse = {
                  ...warehouse,
                  shippingAddress: correctedAddress,
                };
                reset(updatedWarehouse);
                setIsAddressConfirmationModalOpen(false);
              }}
              className='rounded-md bg-hopstack-blue-600 flex gap-2 px-4 py-2 h-fit text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
            >
              Accept the Address
            </button>
            <button
              onClick={() => {
                setIsAddressConfirmationModalOpen(false);
              }}
              className='bg-hopstack-blue-700 flex gap-2 rounded-lg text-white py-2 text-sm font-semibold px-4 hover:opacity-80'
            >
              Edit address manually
            </button>
          </div>
        </div>
      </Modal>
      {/* <Notification error={error} setShow={setShowNotification} show={showNotification} /> */}
      {!warehouse && (
        <div className='w-full flex justify-center mt-2'>
          <SignOutButton>
            <button className='rounded-md bg-red-500 flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'>
              Sign Out
            </button>
          </SignOutButton>
        </div>
      )}
    </form>
  );
}
