import React, { useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch';

import CreateOrganization from '../components/Onboarding/CreateOrganization';
import CreateWarehouse from '../components/Onboarding/CreateWarehouse';
import { Organization } from '../components/common/types';
import SelectPlan from '../components/Onboarding/SelectPlan';
import { Link } from 'react-router-dom';

interface OnboardingProps {
  organization?: Organization;
}

export enum OnboardingStep {
  createOrganization,
  createWarehouse,
  selectPlan,
  dashboard,
}

const Onboarding: React.FC<OnboardingProps> = ({ organization }) => {
  const initOrg: Organization = {
    name: '',
    email: '',
    mobileNumber: {
      countryCode: '',
      number: '',
    },
    timezone: '',
    billingAddress: {
      email: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
      country: {
        code: '',
        name: '',
      },
    },
  };

  const authenticatedFetch = useFetch();
  const [warehouse, setWarehouse] = useState();

  const [orgData, setOrgData] = useState<Organization>(organization || initOrg);
  //state for status of organization step
  const [orgStepStatus, setOrgStepStatus] = useState<string>('incomplete');
  //state for status of warehouse step
  const [warehouseStepStatus, setWarehouseStepStatus] = useState<string>('incomplete');
  //state for status of plan step
  const [planStepStatus, setPlanStepStatus] = useState<string>('incomplete');

  const [onboardingStep, setOnboardingStep] = useState<OnboardingStep>(
    OnboardingStep.createOrganization,
  );

  const fetchWarehouses = (orgId: string) => {
    authenticatedFetch(`/api/v1/warehouses?filter[organizationId]=${orgId}`).then((res) => {
      if (res.success && res.data && res.data.length > 0) {
        setWarehouse(res.data[0]);
        setCurrentStep(OnboardingStep.selectPlan);
      }
    });
  };

  const steps = [
    {
      id: 'Step 1',
      name: 'Create Organization',
      href: '#organization',
      status: orgStepStatus,
    },
    {
      id: 'Step 2',
      name: 'Create Warehouse',
      href: '#warehouse',
      status: warehouseStepStatus,
    },
    {
      id: 'Step 3',
      name: 'Plan Selection',
      href: '#plan',
      status: planStepStatus,
    },
  ];

  const setCurrentStep = (step: OnboardingStep) => {
    setOnboardingStep(step);
    if (step === OnboardingStep.createOrganization) {
      setOrgStepStatus('current');
      setWarehouseStepStatus('incomplete');
      setPlanStepStatus('incomplete');
    } else if (step === OnboardingStep.createWarehouse) {
      setOrgStepStatus('complete');
      setWarehouseStepStatus('current');
      setPlanStepStatus('incomplete');
    } else if (step === OnboardingStep.selectPlan) {
      setOrgStepStatus('complete');
      setWarehouseStepStatus('complete');
      setPlanStepStatus('current');
    }
  };

  useEffect(() => {
    if (organization) {
      setOrgData(organization);
      if (organization.subscription?.status === 'active') {
        window.location.href = `/${organization.organizationId}`;
      } else {
        fetchWarehouses(organization.organizationId);
        setCurrentStep(OnboardingStep.createWarehouse);
      }
    }
  }, [organization]);

  return (
    <div className='p-8'>
      <nav aria-label='Progress'>
        <ol role='list' className='space-y-4 md:flex md:space-x-8 md:space-y-0'>
          {steps.map((step, index) => (
            <li key={index} className='md:flex-1'>
              {step.status === 'complete' ? (
                <Link
                  to={step.href}
                  className='group flex flex-col border-l-4 border-hopstack-blue-600 py-2 pl-4 hover:border-hopstack-blue-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4'
                >
                  <span className='text-sm font-medium text-hopstack-blue-600 group-hover:text-hopstack-blue-800'>
                    {step.id}
                  </span>
                  <span className='text-sm font-medium'>{step.name}</span>
                </Link>
              ) : step.status === 'current' ? (
                <Link
                  to={step.href}
                  className='flex flex-col border-l-4 border-green-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4'
                  aria-current='step'
                >
                  <span className='text-sm font-medium text-hopstack-blue-600'>{step.id}</span>
                  <span className='text-sm font-medium'>{step.name}</span>
                </Link>
              ) : (
                <Link
                  to={step.href}
                  className='group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4'
                >
                  <span className='text-sm font-medium text-gray-500 group-hover:text-gray-700'>
                    {step.id}
                  </span>
                  <span className='text-sm font-medium'>{step.name}</span>
                </Link>
              )}
            </li>
          ))}
        </ol>
      </nav>
      {onboardingStep === OnboardingStep.createOrganization && (
        <CreateOrganization
          orgData={orgData}
          setOrgData={setOrgData}
          setOnboardingStep={setCurrentStep}
        />
      )}
      {onboardingStep === OnboardingStep.createWarehouse && (
        <CreateWarehouse
          orgData={orgData}
          warehouse={warehouse}
          setOnboardingStep={setCurrentStep}
          setSelectedWarehouse={setWarehouse}
          allowUsingOrgData={true}
        />
      )}
      {onboardingStep === OnboardingStep.selectPlan && (
        <SelectPlan setOnboardingStep={setCurrentStep} />
      )}
    </div>
  );
};

export default Onboarding;
