import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MARKETPLACE_DATA } from '../../utils/ConnectionConfig';
import InfiniteScrollingTable, { Column } from '../common/InfiniteScrollingTable';
import { Client, Integration } from '../common/types';

interface ConnectionTableProps {
  connections: Integration[];
  fetchMoreData: () => void;
  hasMore: boolean;
  isFetching: boolean;
}

export const ConnectionTable: React.FC<ConnectionTableProps> = ({
  connections: connections,
  fetchMoreData,
  hasMore,
  isFetching,
}) => {
  const navigate = useNavigate();

  const columns: Column<Integration & { merchant: Client }>[] = [
    {
      key: 'connectionName',
      title: 'Connection Name',
      accessor: (connection) => (
        <div className='pl-7 text-start w-fit'>
          <a
            href="#"
            className='text-hopstack-blue-700 underline'
            onClick={(event) => {
              event.stopPropagation();
              handleConnectionClick(connection);
            }}
          >
            {connection.integrationName}
          </a>
        </div>
      ),
      headerClassnamePerColumn: 'inline-flex pl-10',
    },
    {
      key: 'region',
      title: 'Region',
      accessor: (connection) => {
        const regionCode = connection.credentials.region?.toUpperCase();
        const marketplaceItem = MARKETPLACE_DATA.find((item) => item.regionCode === regionCode);
        return marketplaceItem ? marketplaceItem.region : 'Unknown';
      },
    },
    {
      key: 'marketplace',
      title: 'Marketplace',
      accessor: (connection) => (
        <div className='line-clamp-3'>
          {connection.connections
            .flatMap((con) => {
              const marketplace = MARKETPLACE_DATA.find(
                (data) => data.marketplaceId === con?.details?.marketplaceId,
              );
              return marketplace ? [marketplace.countryCode] : [];
            })
            .join(', ')}
        </div>
      ),
    },
    {
      key: 'connectionName',
      title: 'Connection Name',
      accessor: (connection) => (
        <div className='capitalize rounded-full py-1 w-fit px-2 inline-flex'>
          {connection.merchant.name}
        </div>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      accessor: (connection) => (
        <span
          className={`px-3 py-1 rounded-full font-medium capitalize ${
            connection.status === 'enabled'
              ? 'bg-[#dcfce7] text-[#166534]'
              : 'bg-[#f1f5f9] text-[#475569]'
          }`}
        >
          {connection.status}
        </span>
      ),
    },
  ];

  const handleConnectionClick = (connection) => {
    navigate(`${connection.integrationId}`);
  };

  return (
    <InfiniteScrollingTable
      onSort={() => {}}
      totalDataCount={connections.length}
      columns={columns}
      data={connections}
      fetchMoreData={fetchMoreData}
      hasMore={hasMore}
      loadingData={isFetching && connections.length === 0}
      onRowClick={handleConnectionClick}
    />
  );
};

export default ConnectionTable;
