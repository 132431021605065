import React, { useContext, useState, useCallback } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import BaseContext from '../common/BaseContext';
import { Client } from '../common/types';
import Cookies from 'universal-cookie';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import Dropdown from '../common/Dropdown';

interface ShopifyIntegrationProps {
  merchants: Client[];
  selectedIntegration: {
    icon: string;
  };
  setSelectedIntegration: (integration: any | null) => void;
  setIsIntegrationsModalOpen: (isOpen: boolean) => void;
}

interface FormData {
  shopName: string;
}

interface IntegrationData {
  merchantId: string;
  integrationName: string;
  organizationId: string;
  connections: Array<{
    connectionName: string;
    status: string;
    details: {
      shop: string;
    };
  }>;
}

const ShopifyIntegration: React.FC<ShopifyIntegrationProps> = ({
  merchants,
  selectedIntegration,
  setSelectedIntegration,
  setIsIntegrationsModalOpen,
}) => {
  const { setLoading, setShowNotification, organization } = useContext(BaseContext);
  const [client, setClient] = useState<Client | null>(null);
  const cookies = new Cookies(null, { path: '/' });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const handleAuthorize: SubmitHandler<FormData> = useCallback(
    async (data) => {
      if (!client || !organization) return;
      try {
        setLoading(true);
        const url = `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/api/public/v1/integrations/shopify/oauth?shop=${data.shopName}`;

        const integrationData: IntegrationData = {
          merchantId: client.merchantId,
          integrationName: 'Shopify',
          organizationId: organization.organizationId,
          connections: [
            {
              connectionName: 'default',
              status: 'enabled',
              details: {
                shop: data.shopName,
              },
            },
          ],
        };

        cookies.set('integration_data', JSON.stringify(integrationData));

        window.location.href = url;
        setIsIntegrationsModalOpen(false);
      } catch (error) {
        setLoading(false);
        setShowNotification({
          show: true,
          content: 'Error authorizing integration',
          type: 'error',
        });
        console.error('Error authorizing integration:', error);
      }
    },
    [client, organization, setLoading, setIsIntegrationsModalOpen, setShowNotification],
  );

  return (
    <div>
      <img
        width={200}
        height={200}
        className='mb-5'
        src={selectedIntegration.icon}
        alt='Integration icon'
      />

      <label className='block text-[15px] font-medium text-left leading-6 text-gray-900'>
        Select Client
      </label>
      <p className='text-[12px] text-gray-500'>
        Select the client for whom you would like to enable this connection
      </p>

      <Dropdown<Client>
        items={merchants}
        selectedItem={client}
        setSelectedItem={setClient}
        getItemId={(item) => item.merchantId}
        getItemDisplayValue={(item) => item.name}
        placeholder='Select a client'
      />

      <form onSubmit={handleSubmit(handleAuthorize)}>
        <div className='sm:col-span-4 mt-6'>
          <label
            htmlFor='shopName'
            className='block text-[15px] font-medium text-left leading-6 text-gray-900'
          >
            Shopify Shop Name
          </label>
          <p className='text-[12px] text-gray-500'>Enter your Shopify shop name</p>
          <div className='mt-2'>
            <input
              id='shopName'
              {...register('shopName', { required: 'Shop Name is required' })}
              placeholder='Eg. test-store.myshopify.com'
              className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none placeholder:text-gray-400 sm:text-sm sm:leading-6'
            />
            {errors.shopName && <span className='text-red-500'>{errors.shopName.message}</span>}
          </div>
        </div>

        <div className='flex mt-4 gap-2'>
          <button
            type='button'
            onClick={() => setSelectedIntegration(null)}
            className='rounded-md bg-hopstack-blue-500 flex gap-2 px-5 text-nowrap py-2 h-fit text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
          >
            <ArrowLeftIcon className='w-4 h-4 my-auto' />
            Go Back
          </button>

          <button
            type='submit'
            disabled={!client || merchants.length === 0}
            className={`rounded-md flex gap-2 px-8 py-2 text-sm font-semibold text-nowrap text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600 ${
              client && merchants.length > 0
                ? 'bg-hopstack-blue-700 hover:bg-hopstack-blue-700/80'
                : 'bg-gray-400 cursor-not-allowed'
            }`}
          >
            {client && merchants.length > 0 ? 'Authorize' : 'Cannot authorize without client'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ShopifyIntegration;
