import React from 'react';

export type Column<T> = {
  header: string | (() => React.ReactNode);
  accessor: (row: T, index?: number) => React.ReactNode;
  headerClassnamePerColumn?: string;
};

type DynamicTableProps<T> = {
  columns: Column<T>[];
  data: T[];
  headerClassname?: string;
  rowsClassname?: string;
  onRowClick?: (data: any) => void;
};

// Usage example to handle the union type in a component
const renderHeader = (header: string | (() => React.ReactNode)) => {
  if (typeof header === 'string') {
    return header;
  } else {
    return header();
  }
};

const DynamicTable = <T,>({
  columns,
  data,
  headerClassname,
  rowsClassname,
  onRowClick = () => {},
}: DynamicTableProps<T>) => {
  return (
    <div className='overflow-x-scroll flex-grow rounded-md shadow-lg w-full scroll-smooth h-full'>
      <table className='relative min-w-full bg-white text-start h-full'>
        <thead className={`w-full`}>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className={`p-4 bg-gray-50 text-start ${headerClassname} ${column.headerClassnamePerColumn}`}
              >
                {renderHeader(column.header)}
              </th>
            ))}
          </tr>
        </thead>
        {data.length > 0 ? (
          <tbody className='w-full max-h-screen'>
            {data.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={`odd:bg-gray-100/70 hover:bg-slate-200/70 transition-colors duration-300 ease-in-out`}
                onClick={() => {
                  onRowClick(row);
                }}
              >
                {columns.map((column, colIndex) => (
                  <td key={colIndex} className={`p-4 max-h-fit ${rowsClassname}`}>
                    {column.accessor(row, colIndex)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        ) : (
          ''
        )}
      </table>
    </div>
  );
};

export default DynamicTable;
