import React, { Fragment, useContext, useRef } from 'react';
import useFetch from '../hooks/useFetch';
import { CheckIcon } from '@heroicons/react/24/outline';
import { Dialog, Transition } from '@headlessui/react';
import BaseContext from '../components/common/BaseContext';

interface UserInvitation {
  warehouseIds: string[];
  merchantIds: string[];
  email: string;
  userInvitationId: string;
  organizationId: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  clerkInvitationId: string;
  invitedBy: string;
  role: string;
  userEmail: string;
  organizationName: string;
}

interface UserInvitationProps {
  invitation: UserInvitation;
}
const UserInvitation: React.FC<UserInvitationProps> = ({ invitation }) => {
  const authenticatedFetch = useFetch();
  const { setShowNotification } = useContext(BaseContext);

  const cancelButtonRef = useRef(null);

  //TODO manage loading states

  const respond = (response: string) => {
    authenticatedFetch('/api/v1/user-invitations/respond', {
      method: 'POST',
      body: JSON.stringify({
        invitationId: invitation.userInvitationId,
        action: response,
      }),
    }).then((res) => {
      if (res.success) {
        location.replace('/');
      } else {
        setShowNotification({ show: true, type: 'failure', content: res.errors[0]?.message });
      }
    });
  };

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as='div' className='relative z-10' initialFocus={cancelButtonRef} onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                <div>
                  <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100'>
                    <CheckIcon className='h-6 w-6 text-green-600' aria-hidden='true' />
                  </div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title as='h3' className='text-base leading-6 text-gray-900'>
                      <b>
                        <em>{invitation?.invitedBy}</em>
                      </b>
                      {' '}has invited you to join{' '}
                      <b>
                        <em>{invitation?.organizationName}</em>
                      </b>{' '}
                      as{' '}
                      <b>
                        <em>{invitation?.role}</em>
                      </b>
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500'></p>
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center rounded-md bg-hopstack-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600 sm:col-start-2'
                    onClick={() => respond('accept')}
                  >
                    Accept
                  </button>
                  <button
                    type='button'
                    className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0'
                    onClick={() => respond('decline')}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UserInvitation;
