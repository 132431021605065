import React from 'react';
import { Menu } from '@headlessui/react';

import InfiniteScrollingTable, { Column } from '../common/InfiniteScrollingTable';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Client } from '../common/types';

interface ClientTableProps {
  clients: Client[];
  setEditMode: (mode: boolean) => void;
  reset: (client: Client) => void;
  setOpen: (open: boolean) => void;
  changeStatusClient: (client: Client, status: string) => Promise<void>;
  fetchMoreData: () => void;
  hasMore: boolean;
  isFetching: boolean;
}

const ClientTable: React.FC<ClientTableProps> = ({
  clients,
  setEditMode,
  reset,
  setOpen,
  changeStatusClient,
  fetchMoreData,
  hasMore,
  isFetching,
}) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'active':
        return 'text-green-700 bg-green-100';
      case 'inactive':
        return 'text-gray-700 bg-gray-100';
      default:
        return '';
    }
  };

  const columns: Column<Client>[] = [
    {
      key: 'name',
      title: 'Name',
      accessor: (client) => (
        <div className='whitespace-nowrap text-sm font-medium text-gray-900'>{client.name}</div>
      ),
    },
    {
      key: 'email',
      title: 'Email',
      accessor: (client) => (
        <div className='whitespace-nowrap text-sm text-gray-500'>{client.email}</div>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      accessor: (client) => (
        <div className='whitespace-nowrap text-sm'>
          <span
            className={`px-2 inline-flex text-sm leading-5 capitalize font-semibold rounded-full ${getStatusColor(
              client.status,
            )}`}
          >
            {client.status}
          </span>
        </div>
      ),
    },
    {
      key: 'actions',
      title: 'Actions',
      accessor: (client) => (
        <Menu as='div' className='relative overflow-visible'>
          <div>
            <Menu.Button className=''>
              <EllipsisVerticalIcon className='w-8 h-8 px-1' />
            </Menu.Button>
          </div>
          <Menu.Items className='absolute z-20 overflow-visible top-10 ml-2 w-32 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
            <div>
              <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                <button
                  onClick={() => {
                    setEditMode(true);
                    reset(client);
                    setOpen(true);
                  }}
                  className='flex gap-2 w-full'
                >
                  <div className='text-[12px]'>Edit Client</div>
                </button>
              </Menu.Item>
              {client.status === 'active' && (
                <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                  <button
                    onClick={() => changeStatusClient(client, 'inactive')}
                    className='flex gap-2 w-full'
                  >
                    <div className='text-[12px] text-red-500'>Deactivate Account</div>
                  </button>
                </Menu.Item>
              )}
              {client.status === 'inactive' && (
                <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                  <button
                    onClick={() => changeStatusClient(client, 'active')}
                    className='flex gap-2 w-full'
                  >
                    <div className='text-[12px] text-green-500'>Reactivate Account</div>
                  </button>
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Menu>
      ),
    },
  ];

  return (
    <div className='shadow ring-1 mt-4 ring-black ring-opacity-5 sm:rounded-lg'>
      <InfiniteScrollingTable
        onSort={() => {}}
        totalDataCount={clients.length}
        columns={columns}
        data={clients}
        fetchMoreData={fetchMoreData}
        hasMore={hasMore}
        loadingData={isFetching && clients.length === 0}
        onRowClick={() => {}}
      />
    </div>
  );
};

export default ClientTable;
