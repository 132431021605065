import React, { useEffect } from 'react';
import { Menu } from '@headlessui/react';
import InfiniteScrollingTable, { Column } from '../common/InfiniteScrollingTable';

import { getMarketplace } from '../../utils/ConnectionConfig';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

type Product = {
  listing: {
    images: string[];
    name: string;
    marketplaceData?: {
      marketplaceId?: string;
      asin?: string;
      fnSku?: string;
    };
  };
  merchant?: {
    name?: string;
  };
  expectedQuantity?: number;
  totalQuantity?: number;
  allocatedQuantity?: number;
  unfulfillableQuantity?: number;
};

interface InventoryTableProps {
  products: Product[];
  fetchInventory: () => void;
  hasMore: boolean;
  isFetching: boolean;
  setSelectedInventory: (product: Product) => void;
}

const InventoryTable: React.FC<InventoryTableProps> = ({
  products,
  fetchInventory,
  hasMore,
  isFetching,
  setSelectedInventory,
}) => {
  const columns: Column<Product>[] = [
    {
      key: 'image',
      title: 'Image',
      accessor: (product) => (
        <div className='flex items-center justify-center'>
          <img
            className='object-scale-down h-24 w-24 rounded-md'
            src={product.listing.images[0]}
            alt={product.listing.name}
          />
        </div>
      ),
    },
    {
      key: 'name',
      title: 'Name',
      accessor: (product) => (
        <div className='px-3 py-4 text-sm text-gray-500 max-w-[20rem] text-wrap'>
          <div className='line-clamp-3 '>{product.listing.name}</div>
        </div>
      ),
    },
    {
      key: 'identifiers',
      title: 'Identifiers',
      accessor: (product) => (
        <div className='whitespace-nowrap text-sm text-gray-500'>
          <a
            href={`${
              getMarketplace(product.listing.marketplaceData?.marketplaceId).marketplaceUrl
            }/dp/${product.listing.marketplaceData?.asin}`}
            target='blank'
            className='hover:underline'
          >
            <span className='font-bold'>ASIN: </span>
            {product.listing.marketplaceData?.asin}
          </a>
          <br />
          <span className='font-bold'>FNSKU: </span>
          {product.listing.marketplaceData?.fnSku}
          {product.listing.marketplaceData?.marketplaceId && (
            <div>
              <span className='font-bold'>Marketplace: </span>
              {getMarketplace(product.listing.marketplaceData.marketplaceId).country}
            </div>
          )}
        </div>
      ),
    },
    {
      key: 'clientName',
      title: 'Client Name',
      accessor: (product) => product.merchant?.name || '',
    },
    {
      key: 'expectedQuantity',
      title: 'Expected Quantity',
      accessor: (product) => product.expectedQuantity || 0,
    },
    {
      key: 'totalQuantity',
      title: 'Total Quantity',
      accessor: (product) => product.totalQuantity || 0,
    },
    {
      key: 'allocatedQuantity',
      title: 'Allocated',
      accessor: (product) => product.allocatedQuantity || 0,
    },
    {
      key: 'unfulfillableQuantity',
      title: 'Unfulfillable Quantity',
      accessor: (product) => product.unfulfillableQuantity || 0,
    },
    {
      key: 'fulfillableQuantity',
      title: 'Fulfillable Quantity',
      accessor: (product) => (product.totalQuantity || 0) - (product.allocatedQuantity || 0),
    },
    {
      key: 'action',
      title: 'Action',
      accessor: (product) => (
        <Menu as='div' className='relative overflow-visible'>
          <div>
            <Menu.Button className=''>
              <EllipsisVerticalIcon className='w-8 h-8 px-1' />
            </Menu.Button>
          </div>
          <Menu.Items className='absolute z-20 overflow-visible bottom-10 p-1 -ml-24 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
            <div>
              <Menu.Item as='div' className='p-2 text-wrap hover:bg-gray-100'>
                <button onClick={() => setSelectedInventory(product)} className='gap-2 w-full'>
                  <div className='text-[12px] text-wrap w-32'>Adjust Inventory</div>
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      ),
    },
  ];

  useEffect(() => {
    console.log(isFetching);
  }, [isFetching]);

  return (
    <InfiniteScrollingTable
      onSort={() => {}}
      totalDataCount={products.length}
      columns={columns}
      data={products}
      fetchMoreData={fetchInventory}
      hasMore={hasMore}
      loadingData={isFetching && products.length === 0}
      onRowClick={() => {}}
    />
  );
};

export default InventoryTable;
