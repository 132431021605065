import React from 'react';

interface LoadingIndicatorProps {
  shouldShowOnPage?: boolean;
  color?: string;
  className?: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  shouldShowOnPage = true,
  color = 'text-hopstack-blue-700',
  className = '',
}) => {
  return shouldShowOnPage ? (
    <div
      className={`fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center font-bold ${className} ${color}`}
    >
      <svg
        className='-ml-1 mr-3 h-10 w-10'
        style={{ animation: 'spin 0.7s linear infinite' }} // Increased spin rate to 0.5 seconds
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
      >
        <circle
          className='opacity-25'
          cx='12'
          cy='12'
          r='10'
          stroke='currentColor'
          strokeWidth='4'
        />
        <path
          className='opacity-75'
          fill='currentColor'
          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
        />
      </svg>
    </div>
  ) : (
    <div className={`flex items-center justify-center ${className} ${color}`}>
      <svg
        className='-ml-1 mr-3 h-8 w-8'
        style={{ animation: 'spin 0.7s linear infinite' }} // Increased spin rate to 0.5 seconds
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
      >
        <circle
          className='opacity-25'
          cx='12'
          cy='12'
          r='10'
          stroke='currentColor'
          strokeWidth='4'
        />
        <path
          className='opacity-75'
          fill='currentColor'
          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
        />
      </svg>
    </div>
  );
};

export default LoadingIndicator;
