import React, { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface Filter {
  id: string;
  component: React.ReactNode;
}

interface FilterPanelProps {
  children: ReactNode;
  onApplyFilters: (filters: Record<string, string[]>) => void;
  onCancel: () => void;
  handleResetFilters: () => void;
  classNames?: {
    panel?: string;
    header?: string;
    title?: string;
    clearButton?: string;
    resetButton?: string;
    filterItem?: string;
    cancelButton?: string;
    confirmButton?: string;
  };
}

const FilterPanel: React.FC<FilterPanelProps> = ({
  children,
  onApplyFilters,
  handleResetFilters,
  onCancel,
  classNames = {},
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   // Load initial filters from URL
  //   const urlFilters = parseFiltersFromUrl();
  //   console.log(urlFilters);
  //   if (Object.keys(urlFilters).length > 0) {
  //     onApplyFilters(urlFilters);
  //   }
  // }, []);

  const parseFiltersFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    const filters: Record<string, string[]> = {};
    searchParams.forEach((value, key) => {
      if (filters[key]) {
        filters[key].push(value);
      } else {
        filters[key] = [value];
      }
    });
    return filters;
  };

  const updateUrlWithFilters = (filters: Record<string, string[]>) => {
    const searchParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, values]) => {
      values.forEach((value) => searchParams.append(key, value));
    });
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  const handleApplyFilters = () => {
    const filters = parseFiltersFromUrl();

    onApplyFilters(filters);
    updateUrlWithFilters(filters);
  };

  const handleResetFiltersWithUrl = () => {
    handleResetFilters();
    navigate({ search: '' }, { replace: true });
  };

  return (
    <div className={`bg-white p-4 rounded-lg shadow-lg ${classNames.panel}`}>
      <div className={`flex justify-between items-center mb-4 ${classNames.header}`}>
        <h2 className={`text-lg font-bold ${classNames.title}`}>Filters</h2>
        <div>
          <button
            className={`text-red-500 hover:underline ${classNames.resetButton}`}
            onClick={handleResetFiltersWithUrl}
          >
            Reset Filters
          </button>
        </div>
      </div>
      {children}
      <div className='flex justify-center gap-5 mt-5'>
        <button
          className={`px-4 py-1 text-hopstack-blue-700 bg-white border border-hopstack-blue-700/25 rounded-lg hover:bg-hopstack-blue-100 mr-2 ${classNames.cancelButton}`}
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className={`px-4 py-1 text-white bg-hopstack-blue-700 rounded-lg hover:bg-hopstack-blue-700/90 ${classNames.confirmButton}`}
          onClick={handleApplyFilters}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default FilterPanel;
