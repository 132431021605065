import React, { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import useFetch from '../hooks/useFetch';
import BaseContext from '../components/common/BaseContext';

const AmazonAuthRedirection = () => {
  const cookies = new Cookies(null, { path: '/' });

  const [searchParams] = useSearchParams();
  const authenticatedFetch = useFetch();
  const { setShowNotification, setLoading } = useContext(BaseContext);
  const integrationData = cookies.get('integration_data');
  cookies.remove('integration_data');

  useEffect(() => {
    if (searchParams.get('spapi_oauth_code') && integrationData?.organizationId) {
      setLoading(true);
      authenticatedFetch('/api/v1/integrations', {
        method: 'POST',
        body: JSON.stringify({
          merchantId: integrationData?.merchantId,
          integrationName: integrationData?.integrationName,
          credentials: {
            sellerId: searchParams.get('selling_partner_id'),
            spApiAuthCode: searchParams.get('spapi_oauth_code'),
            region: integrationData?.region,
          },
          connections: integrationData?.connections,
        }),
      }).then((res) => {
        setLoading(false);
        if (res.success) {
          location.replace(`/${integrationData?.organizationId}/connections`);
        } else {
          setTimeout(function () {
            location.replace(`/${integrationData?.organizationId}/connections`);
          }, 1000);
          setShowNotification({
            show: true,
            type: 'failure',
            content: res.message || res.errors[0]?.message,
          });
        }
      });
    }
  }, [integrationData?.organizationId]);
  return <div className='animate-pulse m-10 text-[18px]'>Adding Connection...</div>;
};

export default AmazonAuthRedirection;
