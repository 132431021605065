import React from 'react';
import { Link } from 'react-router-dom';
import InfiniteScrollingTable, { Column } from '../common/InfiniteScrollingTable';
import { Organization } from '../common/types';
import { InboundOrder, InboundOrderStatus } from './types';

interface InboundOrderTableProps {
  orders: InboundOrder[];
  handleOrderClick: (order: InboundOrder) => void;
  organization: Organization;
  fetchMoreData: () => void;
  hasMore: boolean;
  isFetching: boolean;
}

const getInboundOrderStatusColor = (status: InboundOrderStatus) => {
  switch (status) {
    case InboundOrderStatus.COMPLETED:
      return 'text-green-700 bg-green-100';
    case InboundOrderStatus.PROCESSING:
      return 'text-yellow-700 bg-yellow-100';
    case InboundOrderStatus.ON_HOLD:
      return 'text-gray-700 bg-gray-200';
    case InboundOrderStatus.UNPROCESSED:
      return 'text-gray-700 bg-gray-200';
    case InboundOrderStatus.CANCELLED:
      return 'text-red-700 bg-red-100';
    case InboundOrderStatus.PAUSED:
      return 'text-yellow-700 bg-yellow-100';
    default:
      return '';
  }
};

const InboundOrderTable: React.FC<InboundOrderTableProps> = ({
  orders,
  handleOrderClick,
  organization,
  fetchMoreData,
  hasMore,
  isFetching,
}) => {
  const columns: Column<InboundOrder>[] = [
    {
      key: 'shipmentId',
      title: 'Shipment ID',
      accessor: (order) => (
        <Link
          className='hover:underline'
          to={`/${organization.organizationId}/inbound/${order?.inboundOrderId}`}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {order?.inboundOrderDisplayId}
        </Link>
      ),
    },
    {
      key: 'clientName',
      title: 'Client Name',
      accessor: (order) => order?.merchant?.name || '',
    },
    {
      key: 'numberOfProducts',
      title: 'No. of Products',
      accessor: (order) => <div className='pl-10'>{order?.products?.length || 0}</div>,
    },
    {
      key: 'quantity',
      title: 'Quantity',
      accessor: (order) => (
        <div className='pl-10'>
          {order?.products?.reduce((acc, product) => acc + product.expectedQuantity, 0) || 0}
        </div>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      accessor: (order) => (
        <div className='relative group w-fit mx-auto'>
          <div
            className={`py-1 px-3 text-sm mt-2 rounded-full capitalize ${getInboundOrderStatusColor(
              order.status,
            )}`}
          >
            {order?.status}
          </div>
        </div>
      ),
      headerClassnamePerColumn: 'inline-flex justify-center w-full',
    },
  ];

  return (
    <InfiniteScrollingTable
      onSort={() => {}}
      totalDataCount={orders.length}
      columns={columns}
      data={orders}
      fetchMoreData={fetchMoreData}
      hasMore={hasMore}
      loadingData={isFetching && orders.length === 0}
      onRowClick={handleOrderClick}
    />
  );
};

export default InboundOrderTable;
