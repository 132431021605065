import React, { useState, useEffect } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Fragment } from 'react';
import Dropdown from '../common/Dropdown';
import useRequest from '../../hooks/useRequest';
import { Spinner } from '../common/Spinner';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
interface Location {
  id: string;
  name: string;
  address: {
    address1: string;
    address2: string | null;
    city: string;
    provinceCode: string;
    zip: string;
    countryCode: string;
  };
  isActive: boolean;
  hasActiveInventory: boolean;
  fulfillsOnlineOrders: boolean;
}

interface ShopifyLocationSelectorProps {
  integrationId: string;
  organizationId: string;
}
export const ShopifyLocationSelector: React.FC<ShopifyLocationSelectorProps> = ({
  integrationId,
  organizationId,
}) => {
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const cookies = new Cookies(null, { path: '/' });

  const { executeRequest: fetchShopifyLocations, data: locations } = useRequest<Location[]>(
    '/api/v1/integrations/shopify/{integrationId}/locations',
    [],
  );

  const { executeRequest: updateShopifyLocation } = useRequest<Location>(
    '/api/v1/integrations/shopify/{integrationId}/locations',
    null,
  );

  useEffect(() => {
    fetchLocations();
  }, []);

  async function fetchLocations() {
    setIsLoading(true);
    try {
      // Replace this URL with your actual API endpoint
      const response = await fetchShopifyLocations({
        queryParams: {},
        urlParams: {
          integrationId,
        },
      });
      if (!response.success) {
        throw new Error('Network response was not ok');
      }

      setSelectedLocation(response.data[0]);
    } catch (error) {
      console.error('Error fetching locations:', error);
      // Handle error (e.g., set an error state, show a message to the user)
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSubmit() {
    if (!selectedLocation) return;

    try {
      const response = await updateShopifyLocation(
        {
          queryParams: {},
          urlParams: { integrationId },
        },
        {
          method: 'PUT',
          body: JSON.stringify(selectedLocation),
        },
      );

      if (!response.success) {
        throw new Error('Network response was not ok');
      }

      cookies.remove('integration_data');
      navigate(`/${organizationId}/connections`);

      // Handle successful update (e.g., show success message, close modal)
    } catch (error) {
      console.error('Error updating location:', error);
      // Handle error (e.g., show error message to user)
    }
  }

  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog as='div' className='relative z-40  ' onClose={() => {}}>
          <TransitionChild
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </TransitionChild>

          <div className='fixed inset-0 overflow-y-auto '>
            <div className='flex min-h-full items-center justify-center p-4 text-center '>
              <TransitionChild
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <DialogPanel className='w-full max-w-md transform overflow-visible rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all h-[14rem] flex flex-col justify-center items-center'>
                  <DialogTitle as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                    Select a shopify location
                  </DialogTitle>
                  <div className='mt-2'>
                    {isLoading ? (
                      <div className='inline-flex m-auto gap-4'>
                        <Spinner />
                        <p>Loading locations...</p>
                      </div>
                    ) : (
                      <Dropdown<Location>
                        items={locations}
                        selectedItem={selectedLocation}
                        setSelectedItem={setSelectedLocation}
                        getItemId={(item) => item.id}
                        getItemDisplayValue={(item) => item.name}
                        placeholder='Select a location'
                        className='w-[20rem] '
                      />
                    )}
                  </div>

                  <div className='mt-4 flex justify-center'>
                    <button
                      type='button'
                      className='inline-flex justify-center rounded-md border border-transparent bg-hopstack-blue-100 px-4 py-2 text-sm font-medium text-hopstack-blue-900 hover:bg-hopstack-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-hopstack-blue-500 focus-visible:ring-offset-2'
                      onClick={handleSubmit}
                      disabled={isLoading || !selectedLocation}
                    >
                      Submit
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
