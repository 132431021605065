import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useDownload from '../hooks/useDownload';
import useRequest from '../hooks/useRequest';
import { RequestParams } from '../hooks/useRequest';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Cog6ToothIcon,
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import SlideOver from '../components/common/SlideOver';
import BaseContext from '../components/common/BaseContext';
import { truncate } from '../components/common/utils';
import { Menu } from '@headlessui/react';
import _ from 'lodash';
import AddPallets from '../components/common/FulfillmentDetails/AddPallets';
import Button from '../components/common/Button';
import { apiPaths } from '../utils/ApiPaths';
import { Address, Warehouse } from '../components/common/types';
import { carrierMapping, getMarketplace, MARKETPLACE_DATA } from '../utils/ConnectionConfig';
import { Integration } from './ConnectionDetails';
import Dropdown from '../components/common/Dropdown';
import { useForm } from 'react-hook-form';
export const fulfillmentDetailNavigation = [
  { name: 'Box' },
  { name: 'Pallet' },
  { name: 'Rate Shopping' },
  { name: 'Product Selection' },
];

type ShipmentType = {
  name: string;
  value: string;
};

export const newBox = {
  weight: null,
  height: null,
  width: null,
  length: null,
  weightUnit: 'pounds',
  dimensionUnit: 'inches',
};

export interface Order {
  lineItems: any;
  status: string;
  fulfillmentId: string;
  integrationConnectionId: string;
  shipmentDetails: Record<string, any>;
  marketplaceData: Record<string, any>;
  merchant: Record<string, any>;
  warehouse?: Warehouse;
  integration?: Integration;
}

/**
 * Formats an address into a string.
 * @param address The address to format.
 * @returns The formatted address string.
 */
export function formatAddress(address: any): string {
  if (!address || Object.keys(address).length === 0) {
    return 'N/A';
  }

  let formattedAddress: string[] = [];

  // Handle the first format
  if (address.Name) {
    formattedAddress = [
      address.Name,
      address.AddressLine1,
      `${address.City}, ${address.StateOrProvinceCode} ${address.PostalCode}`,
      address.CountryCode,
    ];
  }
  // Handle the second format
  else {
    formattedAddress = [
      address.name,
      address.line1,
      address.line2,
      address.line3,
      `${address.city}, ${address.state} ${address.zipCode}${
        address.zipCodeSuffix ? `-${address.zipCodeSuffix}` : ''
      }`,
      address.country.name,
    ];
  }

  // Filter out any undefined or empty lines
  return formattedAddress.filter((line) => line).join('\n');
}

const FulfillmentDetail = () => {
  const authenticatedDownload = useDownload();
  const { setLoading, setShowNotification, organization, setOrganization } =
    useContext(BaseContext);

  const urlParams = useParams();
  const navigate = useNavigate();

  const { executeRequest: fetchOrganizationsData } = useRequest(
    `${apiPaths.GET_ORGANIZATIONS}`,
    [],
  );
  const { executeRequest: fetchOrderData, data: order } = useRequest<Order>(
    `${apiPaths.GET_ORDER}`,
    {
      lineItems: [],
      status: '',
      fulfillmentId: '',
      shipmentDetails: {},
      marketplaceData: {},
      merchant: {},
      integrationConnectionId: '',
    },
  );
  const { executeRequest: cancelTransportRequest } = useRequest(`${apiPaths.CANCEL_TRANSPORT}`, {});
  const { executeRequest: cancelFulfillment } = useRequest(`${apiPaths.CANCEL_FULFILLMENT}`, {});
  const { executeRequest: updateFulfillmentRequest, data: fulfillment } = useRequest<Order>(
    `${apiPaths.GET_ORDER}`,
  );
  const { executeRequest: genrateLabelsRequest } = useRequest(`${apiPaths.GENERATE_LABELS}`, {});
  const { executeRequest: workflowStatusRequest } = useRequest(
    `${apiPaths.LABEL_GENERATION_WORKFLOW_STATUS}`,
    {},
  );
  const { executeRequest: rateShoppingRequest } = useRequest(
    `${apiPaths.RATE_SHOPPING_WORKFLOW}`,
    {},
  );
  const { executeRequest: carrierShippingRequest } = useRequest(
    `${apiPaths.WORK_FLOW_CARRIER_STATUS}`,
    {},
  );

  const shipmentTypes: ShipmentType[] = [
    { name: 'Small Parcel (SP)', value: 'SP' },
    { name: 'Less-Than-Truckload/Full-Truckload (LTL/FTL)', value: 'LTL' },
  ];

  const [shipmentType, setShipmentType] = useState(shipmentTypes[0]);
  const [selectedNav, setSelectedNav] = useState(fulfillmentDetailNavigation[0].name);
  const [selectedBox, setSelectedBox] = useState(0);
  const [open, setOpen] = useState(false);
  const [boxErrors, setBoxErrors] = useState([]);
  const [itemCountInBoxes, setItemCountInBoxes] = useState({});
  const [totalItemQuantity, setTotalItemQuantity] = useState({});
  const [boxErrorMessage, setBoxErrorMessage] = useState(null);
  const [selectedCarrierRateId, setSelectedCarrierRateId] = useState('');
  const [carrierData, setCarrierData] = useState({});
  const [boxes, setBoxes] = useState<any[]>([{ ..._.clone(newBox), name: 'Box1' }]);
  const [showBoxLableDownloadButton, setShowBoxLabelDownloadButton] = useState(false);
  showBoxLableDownloadButton;
  const [submissionType, setSubmissionType] = useState(null);
  const [isShowExternalShipping, setIsShowExternalShipping] = useState(false);
  const [isDisableWorkOnOrderButton, setIsDisableWorkOnOrderButton] = useState<boolean>(false);
  const [pallets, setPallets] = useState<any[]>([
    {
      weight: null,
      height: null,
      width: null,
      length: null,
      weightUnit: 'pounds',
      dimensionUnit: 'inches',
      name: 'Pallet1',
    },
  ]);
  const [workflows, setWorkflows] = useState([]);
  const [boxItems, setBoxItems] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const fetchOrder = async () => {
    try {
      setLoading(true);
      const orderParams: RequestParams = {
        queryParams: { include: 'listing,merchant,warehouse,integration' },
        urlParams: { orderId: urlParams.orderId },
      };
      const orderResponseData = await fetchOrderData(orderParams);
      setTotalItemQuantity(
        orderResponseData.data?.lineItems?.reduce((acc, item) => {
          return {
            ...acc,
            [item.listingId]: item.quantity,
          };
        }, {}),
      );
    } catch (error) {
      console.log('Error fetching order', error);
    } finally {
      setLoading(false);
    }
  };

  const cancelTransport = async (fulfillmentId: string) => {
    try {
      setLoading(true);
      const cancelShipmentParams: RequestParams = { queryParams: {}, urlParams: {} };
      const cancelShipmentResponse = await cancelTransportRequest(cancelShipmentParams, {
        method: 'POST',
        body: JSON.stringify({ fulfillmentId }),
      });

      if (cancelShipmentResponse.success) {
        setShowNotification({
          show: true,
          type: 'success',
          content: cancelShipmentResponse.data,
        });
      } else {
        setShowNotification({
          show: true,
          type: 'failure',
          content: cancelShipmentResponse.message,
        });
      }
    } catch (error) {
      console.log('Error cancelling shipment', error);
    } finally {
      setLoading(false);
    }
  };

  const cancelShipment = async (fulfillmentId: string) => {
    try {
      setLoading(true);
      const cancelShipmentParams: RequestParams = {
        queryParams: {},
        urlParams: {
          fulfillmentId,
        },
      };
      const cancelShipmentResponse = await cancelFulfillment(cancelShipmentParams, {
        method: 'POST',
      });

      if (cancelShipmentResponse.success) {
        setShowNotification({
          show: true,
          type: 'success',
          content: cancelShipmentResponse.message,
        });
      } else {
        setShowNotification({
          show: true,
          type: 'failure',
          content: cancelShipmentResponse.message,
        });
      }
    } catch (error) {
      console.log('Error cancelling shipment', error);
    } finally {
      fetchOrder();
      setLoading(false);
    }
  };

  const generate2DBarcode = async (fulfillmentId: string) => {
    setLoading(true);
    try {
      await authenticatedDownload(
        `2d-barcode-${fulfillmentId}`,
        `/api/v1/fulfillments/${fulfillmentId}/2d-barcode`,
        {
          method: 'POST',
        },
      );
      setShowNotification({
        show: true,
        type: 'success',
        content: 'Download started!',
      });
    } catch (error) {
      setShowNotification({
        show: true,
        type: 'failure',
        content: error?.message || 'Failed to download the file',
      });
    }
    setLoading(false);
  };

  const checkBoxLabel = async () => {
    if (fulfillment) {
      const fulfillmentResponse = await updateFulfillmentRequest({
        queryParams: {},
        urlParams: { orderId: fulfillment.fulfillmentId },
      });

      if (fulfillmentResponse.success) {
        const boxLabels = fulfillmentResponse.data.shipmentDetails.boxLabels;
        if (boxLabels && boxLabels.length > 0) {
          setShowBoxLabelDownloadButton(true);
        } else {
          setTimeout(() => {
            checkBoxLabel();
          }, 1000);
        }
      }
    } else {
      setTimeout(() => {
        checkBoxLabel();
      }, 1000);
    }
  };

  const updateFulfillment = async (fulfillmentData: any, isGenerateLabels: boolean) => {
    try {
      setLoading(true);
      const updateFulfillmentParams: RequestParams = {
        queryParams: {},
        urlParams: { orderId: urlParams.orderId },
      };
      const updateFulfillmentResponse = await updateFulfillmentRequest(updateFulfillmentParams, {
        method: 'PATCH',
        body: JSON.stringify(fulfillmentData),
      });
      if (updateFulfillmentResponse.success) {
        fetchRateShopping();
        if (isGenerateLabels) {
          generateLabels();
          setIsDisableWorkOnOrderButton(true);
        }
        checkBoxLabel();
      } else {
        setShowNotification({
          show: true,
          type: 'failure',
          content: updateFulfillmentResponse.message,
        });

        setSelectedNav(fulfillmentDetailNavigation[0].name);
      }
    } catch (error) {
      console.log('Error updating fulfillment details', error);
    } finally {
      setLoading(false);
    }
  };

  const generateLabels = async () => {
    try {
      setLoading(true);
      const generateLabelsParams: RequestParams = { queryParams: {}, urlParams: {} };
      const generateLabelsResponse = await genrateLabelsRequest(generateLabelsParams, {
        method: 'POST',
        body: JSON.stringify({
          fulfillmentId: urlParams.orderId,
        }),
      });
      if (generateLabelsResponse.success) {
        checkWorkflowStatus(generateLabelsResponse.data.workflowId);
      }
    } catch (error) {
      console.log('Error generating labels', error);
    } finally {
      setLoading(false);
    }
  };

  const checkWorkflowStatus = async (workflowId: string) => {
    try {
      // setLoading(true);
      const workflowStatusRequestParams: RequestParams = { queryParams: {}, urlParams: {} };
      const workflowStatusResponse = await workflowStatusRequest(workflowStatusRequestParams, {
        method: 'POST',
        body: JSON.stringify({
          workflowId,
        }),
      });
      if (workflowStatusResponse.success) {
        if (workflowStatusResponse.data.status === 'COMPLETED') {
          setIsDisableWorkOnOrderButton(false);
          fetchOrder();
          setShowNotification({
            show: true,
            type: 'success',
            content: 'Label generated successfully for fulfillment!',
          });
        } else if (workflowStatusResponse.data.status === 'FAILED') {
          setLoading(false);
          setShowNotification({
            show: true,
            type: 'failure',
            content: 'Unable to generate label for fulfillment',
          });
        } else {
          setTimeout(() => {
            checkWorkflowStatus(workflowId);
          }, 1000);
        }
      } else {
        setShowNotification({
          show: true,
          type: 'failure',
          content: 'Unable to generate label for fulfillment',
        });
      }
    } catch (error) {
      console.log('Error checking workflow status', error);
    } finally {
      // setLoading(false);
    }
  };

  const fetchRateShopping = async () => {
    try {
      setLoading(true);
      const rateShoppingRequestParams: RequestParams = { queryParams: {}, urlParams: {} };
      const rateShoppingResponse = await rateShoppingRequest(rateShoppingRequestParams, {
        method: 'POST',
        body: JSON.stringify({
          fulfillmentId: urlParams.orderId,
        }),
      });
      if (rateShoppingResponse.success) {
        setWorkflows(rateShoppingResponse.data.childWorkflows);
        rateShoppingResponse.data.childWorkflows.forEach((workflow: any) => {
          fetchCarrierShipping(workflow.workflowId);
        });
      }
    } catch (error) {
      console.log('Error fetching rate shipping', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCarrierShipping = async (workflowId: string) => {
    try {
      const carrierShippingRequestParams: RequestParams = { queryParams: {}, urlParams: {} };
      const carrierShippingResponse = await carrierShippingRequest(carrierShippingRequestParams, {
        method: 'POST',
        body: JSON.stringify({
          workflowId,
        }),
      });

      if (carrierShippingResponse.data.status === 'COMPLETED') {
        setCarrierData({ ...carrierData, [workflowId]: carrierShippingResponse.data });
      } else if (carrierShippingResponse.data.status === 'FAILED') {
        console.log(carrierShippingResponse.data.error);
        setCarrierData({
          ...carrierData,
          [workflowId]: { error: carrierShippingResponse.data.error },
        });
      } else {
        setTimeout(() => {
          fetchCarrierShipping(workflowId);
        }, 1000);
      }
    } catch (error) {
      console.log('Error fetching carrier', error);
    }
  };

  const downloadPreppingInstructions = async (fulfillmentId: string) => {
    setLoading(true);
    try {
      await authenticatedDownload(
        'prep-instructions',
        `/api/v1/fulfillments/${fulfillmentId}/prepping-instructions`,
      );
    } catch (e) {
      setShowNotification({
        show: true,
        type: 'failure',
        content: e?.message || 'Failed to download the file',
      });
    }
    setLoading(false);
  };

  const validateBoxes = (boxes) => {
    const errorData = validateContainers(boxes);
    setBoxErrors(errorData.errors);
    const totalItemsInFulfillment = Object.values(totalItemQuantity).reduce((acc, item) => {
      return (acc as number) + (item as number);
    }, 0);
    const totalItemsInBoxes = Object.values(itemCountInBoxes).reduce((acc, item) => {
      return (acc as number) + (item as number);
    }, 0);

    if (errorData.hasError)
      return {
        hasError: true,
        message: 'Please fill all the fields in the boxes',
      };
    if (totalItemsInFulfillment < totalItemsInBoxes) {
      return {
        hasError: true,
        message: 'Total items in boxes cannot be greater than total items in fulfillment',
      };
    } else if (totalItemsInFulfillment > totalItemsInBoxes) {
      return {
        hasError: true,
        message: 'Total items in boxes cannot be less than total items in fulfillment',
      };
    }
    return {
      hasError: false,
    };
  };

  const validateContainers = (containers) => {
    let hasError = false;
    const errors = [];
    containers.forEach((container) => {
      const errorObj = {
        length: {
          hasError: false,
          message: 'Required',
        },
        width: {
          hasError: false,
          message: 'Required',
        },
        height: {
          hasError: false,
          message: 'Required',
        },
        weight: {
          hasError: false,
          message: 'Required',
        },
      };

      if (!container.length) {
        errorObj.length.hasError = true;
        hasError = true;
      }
      if (!container.width) {
        errorObj.width.hasError = true;
        hasError = true;
      }
      if (!container.height) {
        errorObj.height.hasError = true;
        hasError = true;
      }
      if (!container.weight) {
        errorObj.weight.hasError = true;
        hasError = true;
      }
      errors.push(errorObj);
    });
    return {
      errors,
      hasError,
    };
  };

  const resetBoxItems = (boxIndex) => {
    updateBoxItems(
      order?.lineItems?.map((item, itemIndex) => {
        return { ...item, quantityInBox: boxes?.[boxIndex]?.items?.[itemIndex]?.quantity || 0 };
      }),
      boxIndex,
    );
  };

  const updateBoxItems = (boxItems, boxIndex = null) => {
    setBoxItems(boxItems);

    const itemCount = order?.lineItems?.reduce((acc, item) => {
      return {
        ...acc,
        [item.listingId]: 0,
      };
    }, {});

    boxes?.forEach((box, index) => {
      if (index === (boxIndex ?? selectedBox)) {
        boxItems.forEach((item) => {
          itemCount[item.listingId] =
            (item.quantityInBox && itemCount[item.listingId] + item.quantityInBox) ||
            itemCount[item.listingId];
        });
      } else {
        box?.items?.forEach((item) => {
          itemCount[item.listingId] =
            (item.quantity && itemCount[item.listingId] + item.quantity) || 0;
        });
      }
    });

    setItemCountInBoxes(itemCount);
  };

  useEffect(() => {
    try {
      setLoading(true);
      if (urlParams.orgId && !organization) {
        (async () => {
          const organizationParams: RequestParams = {
            queryParams: { include: 'subscription' },
            urlParams: {},
          };
          const organizationsResponse = await fetchOrganizationsData(organizationParams);
          if (organizationsResponse.success && organizationsResponse.data) {
            const org = organizationsResponse.data.find(
              (org) => org.organizationId === urlParams.orgId,
            );
            setOrganization(org);
            if (!org) location.replace('/');
          } else location.replace('/');
        })();
      }
    } catch (error) {
      console.log('Error fetching organizations data', error);
      location.replace('/');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (urlParams.orderId) {
      fetchOrder();
    }
  }, [urlParams.orderId]);

  useEffect(() => {
    //when user reaches rate shopping page
    if (selectedNav === fulfillmentDetailNavigation[2].name) {
      let update: any = {
        lineItems: order?.lineItems.map((item) => {
          return { listingId: item.listingId, quantity: item.quantity };
        }),
        boxes,
        transportMode: shipmentType.value,
      };
      if (shipmentType.value === shipmentTypes[1].value) update = { ...update, pallets };
      updateFulfillment(update, false);
    }
  }, [selectedNav]);

  const onSubmit = async (data) => {
    let updateData;
    if (submissionType === 'rateShopping') {
      updateData = { selectedCarrierRateId };
    } else if (submissionType === 'carrierDetails') {
      updateData = {
        shipmentDetails: {
          carrierName: data.carrierName,
          trackingIds: [data.trackingNumber],
        },
      };
    } else {
      setShowNotification({
        show: true,
        type: 'error',
        content: 'Please select either a shipping rate or provide carrier details.',
      });
      return;
    }

    await updateFulfillment(updateData, true);
    setShowNotification({
      show: true,
      type: 'success',
      content: 'Fulfillment order updated successfully!',
    });
    setSelectedNav(fulfillmentDetailNavigation[0].name);
    fetchOrder();
    setOpen(false);
  };

  const Spinner = () => {
    return (
      <div role='status'>
        <svg
          aria-hidden='true'
          className='w-8 h-8 text-gray-200 animate-spin dark:text-gray-200 fill-hopstack-blue-600'
          viewBox='0 0 100 101'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
            fill='currentColor'
          />
          <path
            d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
            fill='currentFill'
          />
        </svg>
        <span className='sr-only'>Loading...</span>
      </div>
    );
  };
  //Helper functions
  const getCountry = (order: Order, integration: Integration) => {
    const connection = integration?.connections.find(
      (item) => item.connectionId === order.integrationConnectionId,
    );
    const marketplace = MARKETPLACE_DATA.find(
      (item) => item.marketplaceId === connection?.details?.marketplaceId,
    );
    return marketplace;
  };

  return (
    <div className='p-8'>
      <div className='flex justify-between my-2'>
        <div className='flex gap-2'>
          <h1
            className='text-[#030229] text-[24px] cursor-pointer font-bold hover:underline'
            onClick={() => {
              navigate(-1);
            }}
          >
            Order Fulfillment
          </h1>
          <span className='my-auto font-semibold text-[18px]'>{' > Order Details'}</span>
        </div>
        <div className='flex'>
          {order?.status !== 'completed' && order.status !== 'cancelled' && (
            <Button
              onClick={() => setOpen(true)}
              className={`${isDisableWorkOnOrderButton ? ' opacity-50 cursor-not-allowed' : ''} `}
              disabled={isDisableWorkOnOrderButton}
            >
              <Cog6ToothIcon className='w-4 my-auto' />
              <div>Work on Order</div>
            </Button>
          )}
          <Menu as={'div'} className='relative overflow-visible'>
            <div>
              <Menu.Button className=''>
                <EllipsisVerticalIcon className='w-8 h-8 px-1' />
              </Menu.Button>
            </div>
            <Menu.Items className='absolute z-20 overflow-visible top-10 p-1 -ml-24 w-40 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
              <div>
                <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                  <button
                    onClick={() => downloadPreppingInstructions(order?.fulfillmentId)}
                    className='flex gap-2 w-full'
                  >
                    <div className='text-[12px] text-left'>Download Prepping Instructions</div>
                  </button>
                </Menu.Item>
              </div>

              {order?.shipmentDetails?.shipmentId && (
                <div>
                  <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                    <button
                      onClick={() => cancelTransport(order?.fulfillmentId)}
                      className='flex gap-2 w-full'
                    >
                      <div className='text-[12px]'>Cancel Transport</div>
                    </button>
                  </Menu.Item>
                </div>
              )}
              {order?.marketplaceData?.shipmentId &&
                !['completed', 'cancelled'].includes(order.status) && (
                  <div>
                    <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                      <button
                        onClick={() => cancelShipment(order?.fulfillmentId)}
                        className='flex gap-2 w-full'
                      >
                        <div className='text-[12px]'>Cancel Shipment</div>
                      </button>
                    </Menu.Item>
                  </div>
                )}
              {order?.shipmentDetails?.shipmentId &&
                order?.marketplaceData.transportType === '2d_barcode' && (
                  <div>
                    <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                      <button
                        onClick={() => generate2DBarcode(order?.fulfillmentId)}
                        className='flex gap-2 w-full'
                      >
                        <div className='text-[12px]'>Generate 2D Barcode</div>
                      </button>
                    </Menu.Item>
                  </div>
                )}
              {order?.shipmentDetails?.boxLabels &&
                order?.shipmentDetails?.boxLabels.length > 0 && (
                  <div>
                    <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                      <button className='flex gap-2 w-full'>
                        <a
                          href={order?.shipmentDetails?.boxLabels[0]?.publicUrl}
                          className='text-[12px]'
                        >
                          <div className='text-[12px]'>Download Box Label</div>
                        </a>
                      </button>
                    </Menu.Item>
                  </div>
                )}

              {order?.shipmentDetails?.palletLabels &&
                order?.shipmentDetails?.palletLabels.length > 0 && (
                  <div>
                    <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                      <button className='flex gap-2 w-full'>
                        <a
                          href={order?.shipmentDetails?.palletLabels[0]?.publicUrl}
                          className='text-[12px]'
                        >
                          <div className='text-[12px]'>Download Pallet Label</div>
                        </a>
                      </button>
                    </Menu.Item>
                  </div>
                )}

              {order?.shipmentDetails?.shippingLabels &&
                order?.shipmentDetails?.shippingLabels.length > 0 && (
                  <div>
                    <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                      <button className='flex gap-2 w-full'>
                        <a
                          href={order?.shipmentDetails?.shippingLabels[0]?.publicUrl}
                          className='text-[12px]'
                        >
                          Download Shipping Label
                        </a>
                      </button>
                    </Menu.Item>
                  </div>
                )}
            </Menu.Items>
          </Menu>
        </div>
      </div>

      <div className='grid grid-cols-4 gap-3 mt-8'>
        <div>
          <div className='text-[#717679] text-[14px]'>Fulfillment ID</div>
          <div className='text-[#344053] text-[15px]  line-clamp-3 '>{order?.fulfillmentId}</div>
        </div>
        <div>
          <div className='text-[#717679] text-[14px]'>Shipment ID</div>
          <div className='text-[#344053] text-[15px]'>
            {order?.marketplaceData?.shipmentId}
          </div>{' '}
        </div>{' '}
        <div>
          <div className='text-[#717679] text-[14px]'>Client</div>
          <div className='text-[#344053] text-[15px]'>{order?.merchant?.name}</div>
        </div>
        <div>
          <div className='text-[#717679] text-[14px]'>Country</div>
          <div className='text-[#344053] text-[15px]'>
            {getCountry(order, order.integration)?.country}
          </div>
        </div>{' '}
        <div>
          <div className='text-[#717679] text-[14px]'>{`Sender's`} Address</div>
          <div className='relative group'>
            <div className='text-[#344053] text-[15px] capitalize truncate overflow-hidden whitespace-nowrap'>
              {formatAddress(order?.warehouse?.shippingAddress || ({} as Address))}
            </div>
            <div className='absolute left-0 w-full bg-white text-[#344053] text-[15px] capitalize p-2 rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10'>
              {formatAddress(order?.warehouse?.shippingAddress || ({} as Address))}
            </div>
          </div>
        </div>{' '}
        <div>
          <div className='text-[#717679] text-[14px]'>Destination Address</div>
          <div className='relative group'>
            <div className='text-[#344053] text-[15px] capitalize truncate overflow-hidden whitespace-nowrap'>
              {formatAddress(order?.marketplaceData.shipmentPlan?.ShipToAddress || ({} as Address))}
            </div>
            <div className='absolute left-0 w-full bg-white text-[#344053] text-[15px] capitalize p-2 rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10'>
              {formatAddress(order?.marketplaceData.shipmentPlan?.ShipToAddress || ({} as Address))}
            </div>
          </div>
        </div>{' '}
      </div>

      <div className='flex gap-4 my-4'>
        <div className='w-full'>
          <label htmlFor='search' className='sr-only'>
            Search
          </label>
          <div className='relative text-gray-400 focus-within:text-gray-600 border border-gray-300 shadow-sm w-full rounded-md'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
            </div>
            <input
              id='search'
              className='block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-hopstack-blue-600 sm:text-sm sm:leading-6'
              placeholder='Search'
              type='search'
              name='search'
            />
          </div>
        </div>
      </div>
      <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-300'>
          <thead className='bg-gray-50'>
            <tr>
              <th
                scope='col'
                className='py-3.5  pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
              >
                Image
              </th>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 max-w-[100px] text-left text-sm font-semibold text-gray-900 sm:pl-6'
              >
                Product Name
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-balance text-sm font-semibold text-gray-900'
              >
                Identifiers
              </th>
              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Order Qty.
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200 bg-white'>
            {order?.lineItems?.map((lineItem, index) => {
              const product = lineItem.listing;
              return (
                <tr key={index}>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                    {product.images && product.images.length > 0 && (
                      <img
                        className='object-scale-down h-20'
                        src={product?.images[0]}
                        height={100}
                        width={100}
                      />
                    )}
                  </td>
                  <td className='py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 max-w-[20rem]'>
                    {/* <div className='has-tooltip max-w-prose relative'>
                      <span className='tooltip rounded bottom-7 shadow-xl text-[12px] p-1 bg-[#1c1c1c] text-white z-40'>
                        {product.name}
                      </span>
                      {product?.name} <br />
                    </div> */}
                    <div className='line-clamp-3 '>{product.name}</div>
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500 mx-auto'>
                    <div className='w-48 text-wrap mx-auto'>
                      {product?.marketplaceData?.marketplaceId &&
                        product?.marketplaceData?.asin && (
                          <a
                            href={`${
                              getMarketplace(product.marketplaceData.marketplaceId).marketplaceUrl
                            }/dp/${product.marketplaceData.asin}`}
                            target='blank'
                            className='hover:underline'
                          >
                            {' '}
                            <span className='font-bold'>ASIN: </span>
                            {product.marketplaceData.asin + '\n'}
                          </a>
                        )}
                      <br />
                      {product?.marketplaceData?.fnSku && (
                        <>
                          <span className='font-bold'>FNSKU: </span>
                          {product.marketplaceData.fnSku + '\n'}
                          <br />
                        </>
                      )}
                      {product?.marketplaceData?.sellerSku && (
                        <>
                          <span className='font-bold'>Seller SKU: </span>
                          {product.marketplaceData.sellerSku + '\n'}
                          <br />
                        </>
                      )}
                    </div>
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                    {lineItem?.quantity || 0}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <SlideOver open={open} setOpen={setOpen} large={true}>
        <div className='flex justify-between'>
          <h1 className='font-semibold text-[24px]'>Packing</h1>
        </div>
        <div className='flex justify-start gap-6'></div>
        {selectedNav === fulfillmentDetailNavigation[0].name ? (
          <div>
            <h2 className='text-[12px]'>Box Details</h2>
            <div className='rounded-xl border mt-1 max-h-[500px]'>
              <table className='min-w-full divide-y divide-gray-300 rounded-md'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                    >
                      #
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                    >
                      Box Dimension (in)
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Weight (lbs)
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Item Count in Box
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {boxes?.map((box, index) => {
                    return (
                      <tr key={index} className='h-20'>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                          {index + 1}
                        </td>
                        <td className='max-w-48 py-4 pl-4 pr-3 text-sm gap-2 font-medium text-gray-900 sm:pl-6'>
                          <div className='flex gap-2'>
                            <input
                              onChange={(e) =>
                                setBoxes(
                                  boxes.map((box, bIndex) =>
                                    index === bIndex
                                      ? {
                                          ...box,
                                          length: parseFloat(parseFloat(e.target.value).toFixed(2)),
                                        }
                                      : box,
                                  ),
                                )
                              }
                              type='number'
                              className={`w-[40px] text-center py-2 border rounded-xl text-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${
                                boxErrors?.[index]?.length?.hasError ? 'border-red-500' : ''
                              }`}
                              value={box?.length}
                              placeholder='L'
                            />
                            <div className='my-auto'>x</div>
                            <input
                              onChange={(e) =>
                                setBoxes(
                                  boxes.map((box, bIndex) =>
                                    index === bIndex
                                      ? {
                                          ...box,
                                          width: parseFloat(parseFloat(e.target.value).toFixed(2)),
                                        }
                                      : box,
                                  ),
                                )
                              }
                              type='number'
                              className={`w-[40px] text-center py-2 border rounded-xl text-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${
                                boxErrors?.[index]?.width?.hasError ? 'border-red-500' : ''
                              }`}
                              value={box.width}
                              placeholder='W'
                            />
                            <div className='my-auto'>x</div>
                            <input
                              onChange={(e) =>
                                setBoxes(
                                  boxes.map((box, bIndex) =>
                                    index === bIndex
                                      ? {
                                          ...box,
                                          height: parseFloat(parseFloat(e.target.value).toFixed(2)),
                                        }
                                      : box,
                                  ),
                                )
                              }
                              type='number'
                              className={`w-[40px] text-center py-2 border rounded-xl text-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${
                                boxErrors?.[index]?.height?.hasError ? 'border-red-500' : ''
                              }`}
                              value={box.height}
                              placeholder='H'
                            />
                          </div>
                        </td>

                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <input
                            onChange={(e) =>
                              setBoxes(
                                boxes.map((box, bIndex) =>
                                  index === bIndex
                                    ? {
                                        ...box,
                                        weight: parseFloat(parseFloat(e.target.value).toFixed(2)),
                                      }
                                    : box,
                                ),
                              )
                            }
                            type='number'
                            className={`w-[80px] text-center py-2 border rounded-xl text-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${
                              boxErrors?.[index]?.weight?.hasError ? 'border-red-500' : ''
                            }`}
                            value={box.weight}
                            placeholder=''
                          />
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {box?.items?.reduce((acc, item) => {
                            return acc + item.quantity;
                          }, 0) || 0}
                        </td>
                        <td className='px-3 py-4 text-sm text-gray-500'>
                          <div className='flex'>
                            <Menu as={'div'} className='relative overflow-visible'>
                              <div>
                                <Menu.Button className=''>
                                  <EllipsisVerticalIcon className='w-8 h-8 px-1' />
                                </Menu.Button>
                              </div>
                              <Menu.Items className='absolute z-20 overflow-visible top-10 p-1 -ml-24 w-40 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
                                <div>
                                  <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                                    <button
                                      onClick={() => {
                                        setSelectedBox(index);
                                        resetBoxItems(index);
                                        setSelectedNav(fulfillmentDetailNavigation[3].name);
                                      }}
                                      className='flex gap-2 w-full'
                                    >
                                      <div className='text-[12px]'>Add items</div>
                                    </button>
                                  </Menu.Item>
                                </div>
                                <div>
                                  <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                                    <button
                                      onClick={() =>
                                        setBoxes([
                                          ...boxes,
                                          { ..._.clone(newBox), name: `Box${boxes.length + 1}` },
                                        ])
                                      }
                                      className='flex gap-2 w-full'
                                    >
                                      <div className='text-[12px]'>Add another box</div>
                                    </button>
                                  </Menu.Item>
                                </div>
                                {index > 0 && (
                                  <div>
                                    <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                                      <button
                                        onClick={() =>
                                          setBoxes(boxes.filter((b, bIndex) => bIndex !== index))
                                        }
                                        className='flex gap-2 w-full'
                                      >
                                        <div className='text-[12px]'>Delete</div>
                                      </button>
                                    </Menu.Item>
                                  </div>
                                )}
                              </Menu.Items>
                            </Menu>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div>
              <label className='block text-[15px] font-medium text-left leading-6 mt-6 text-gray-900'>
                Type of Shipment
              </label>
              <Dropdown<{ name: string; value: string }>
                items={shipmentTypes}
                selectedItem={shipmentType}
                setSelectedItem={setShipmentType}
                getItemDisplayValue={(item) => item.name}
                getItemId={(item) => item.value}
                placeholder='Select Shipment Type'
              />
            </div>

            {/* <div>
              <label className='block text-[15px] font-medium text-left leading-6 mt-6 text-gray-900'>
                Shipping through
              </label>
              <Dropdown<CarrierType>
                items={carrierTypes}
                selectedItem={carrierType}
                setSelectedItem={setCarrierType}
                getItemDisplayValue={(item) => item.name}
                getItemId={(item) => item.value}
                placeholder='Select Carrier type'
              />
            </div> */}

            <div className='flex gap-2 mt-8'>
              <button
                onClick={() => setOpen(false)}
                className='rounded-md bg-hopstack-blue-600 flex gap-2 px-4 py-2 h-fit text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
              >
                <ArrowLeftIcon className='w-4 h-4 my-auto' />
                Go Back
              </button>
              <button
                // disabled={boxes[0]?.length === null}
                onClick={() => {
                  const validateResponse = validateBoxes(boxes);

                  setBoxErrorMessage(validateResponse.message);
                  if (validateResponse.hasError) {
                    return;
                  }

                  if (shipmentType.value === shipmentTypes[0].value) {
                    setSelectedNav(fulfillmentDetailNavigation[2].name);
                  } else {
                    setSelectedNav(fulfillmentDetailNavigation[1].name);
                  }
                }}
                className='bg-hopstack-blue-700 flex gap-2 rounded-lg text-white py-2 text-sm font-semibold px-4 hover:opacity-80'
              >
                <div className=''>
                  {shipmentType.value === shipmentTypes[1].value ? 'Add Pallet' : 'Continue'}
                </div>
                <ArrowRightIcon className='w-4 h-4 my-auto' />
              </button>
            </div>
            <div className='text-[12px] text-red-500 mt-2'>*{boxErrorMessage}</div>
          </div>
        ) : selectedNav === fulfillmentDetailNavigation[1].name ? (
          <AddPallets
            pallets={pallets}
            setPallets={setPallets}
            setSelectedNav={setSelectedNav}
            validateContainers={validateContainers}
          />
        ) : selectedNav === fulfillmentDetailNavigation[2].name ? (
          <div>
            {!isShowExternalShipping ? (
              <div>
                <h2 className=' mb-4'>Rate Shopping Details</h2>
                <div className='rounded-xl border mt-1 max-h-[500px]'>
                  <table className='min-w-full divide-y divide-gray-300 rounded-md'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                        ></th>
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                        >
                          Carrier
                        </th>
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                        >
                          Estimated Cost (USD)
                        </th>
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                        >
                          Estimated Delivery Time
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {workflows?.map((workflow, index) => (
                        <tr key={index} className='h-20'>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                            <input
                              type='radio'
                              checked={
                                submissionType === 'rateShopping' &&
                                carrierData[workflow.workflowId]?.error === undefined &&
                                selectedCarrierRateId ===
                                  carrierData[workflow.workflowId]?.rates[0]?.shippingRateId
                              }
                              onChange={() => {
                                if (carrierData[workflow.workflowId]?.error === undefined) {
                                  setSelectedCarrierRateId(
                                    carrierData[workflow.workflowId]?.rates[0]?.shippingRateId,
                                  );
                                  setSubmissionType('rateShopping');
                                }
                              }}
                            />
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                            {workflow.integrationName}
                          </td>
                          <td className='max-w-48 py-4 pl-4 pr-3 text-sm gap-2 font-medium text-red-400 sm:pl-6'>
                            {carrierData[workflow.workflowId] ? (
                              carrierData[workflow.workflowId]?.error ? (
                                carrierData[workflow.workflowId]?.error
                              ) : (
                                <p className='text-black'>
                                  {carrierData[workflow.workflowId].rates[0]?.price}
                                </p>
                              )
                            ) : (
                              <Spinner />
                            )}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {carrierData[workflow.workflowId] ? '-' : <Spinner />}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div>
                <div className='font-semibold'>Enter External Shipping Details</div>
                <div className='mb-4 text-xs'>
                  If you have purchased shipping labels for this shipment from an external provider
                  (outside of Ignite), you can enter those details here. Ignite will update these
                  shipping details in Amazon against the relevant shipment. This process may take
                  2-5 minutes.
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='sm:col-span-1 w-full'>
                    <label className='block text-sm font-medium leading-6 text-gray-900'>
                      Carrier Name
                    </label>
                    <div className='mt-1'>
                      <select
                        {...register('carrierName')}
                        className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none'
                        onChange={(e) => {
                          register('carrierName').onChange(e);
                          setSubmissionType('carrierDetails');
                        }}
                      >
                        <option value=''>Select a carrier</option>
                        {Object.entries(carrierMapping).map(([key, { name, value }]) => (
                          <option key={key} value={value}>
                            {name}
                          </option>
                        ))}
                      </select>
                      {errors.carrierName && (
                        <span className='text-red-500'>{errors.carrierName.message as string}</span>
                      )}
                    </div>
                  </div>

                  <div className='sm:col-span-1 w-full mt-4'>
                    <label className='block text-sm font-medium leading-6 text-gray-900'>
                      Tracking Number
                    </label>
                    <div className='mt-1'>
                      <input
                        {...register('trackingNumber')}
                        placeholder='Enter tracking number'
                        className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none'
                        onChange={(e) => {
                          register('trackingNumber').onChange(e);
                          setSubmissionType('carrierDetails');
                        }}
                      />
                      {errors.trackingNumber && (
                        <span className='text-red-500'>
                          {errors.trackingNumber.message as string}
                        </span>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            )}
            {isShowExternalShipping ? (
              <button
                onClick={() => {
                  setIsShowExternalShipping(false);
                  fetchRateShopping();
                }}
                className='bg-white mt-8 py-2 underline h-fit text-sm font-semibold text-hopstack-blue-700'
              >
                Get Shipping Rates
              </button>
            ) : (
              <button
                onClick={() => setIsShowExternalShipping(true)}
                className='bg-white mt-8 py-2 underline h-fit text-sm font-semibold text-hopstack-blue-700'
              >
                I already have shipping details
              </button>
            )}

            <div className='flex gap-2 mt-2'>
              <button
                onClick={() => setSelectedNav(fulfillmentDetailNavigation[0].name)}
                className='rounded-md bg-hopstack-blue-600 flex gap-2 px-4 py-2 h-fit text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
              >
                <ArrowLeftIcon className='w-4 h-4 my-auto' />
                Go Back
              </button>
              <button
                onClick={handleSubmit(onSubmit)}
                className='bg-hopstack-blue-700 flex gap-2 rounded-lg text-white py-2 text-sm font-semibold px-4 hover:opacity-80'
              >
                <div className=''>Submit</div>
                <ArrowRightIcon className='w-4 h-4 my-auto' />
              </button>
              {fulfillment?.shipmentDetails?.boxLabels?.[0]?.publicUrl && (
                <div className='bg-hopstack-blue-700 flex gap-2 rounded-lg text-white py-2 text-sm font-semibold px-4 hover:opacity-80'>
                  <a
                    href={fulfillment.shipmentDetails.boxLabels[0].publicUrl}
                    target='blank'
                    className='text-[12px]'
                  >
                    <div className='text-[12px]'>Download Box Label</div>
                  </a>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className='overflow-hidden mt-4 shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                    >
                      Image
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 max-w-[100px] text-left text-sm font-semibold text-gray-900 sm:pl-6'
                    >
                      Product Name
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Identifiers
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Remaining Qty.
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Quantity in Box.
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {boxItems?.map((lineItem, index) => {
                    const product = lineItem.listing;
                    return (
                      <tr key={index}>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                          {product.images && product.images.length > 0 && (
                            <img src={product?.images[0]} height={100} width={100} />
                          )}
                        </td>
                        <td className=' py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                          <div className='text-wrap max-w-md'>{truncate(product?.name)}</div>
                        </td>
                        <td className='px-3 py-4 text-sm text-gray-500'>
                          <div className='w-48 text-wrap'>
                            <a
                              href={`${
                                getMarketplace(product.marketplaceData?.marketplaceId)
                                  .marketplaceUrl
                              }/dp/${product.marketplaceData?.asin}`}
                              target='blank'
                              className='hover:underline'
                            >
                              <span className='font-bold'>ASIN: </span>
                              {product.marketplaceData?.asin + '\n'}
                            </a>
                            <br />
                            <span className='font-bold'>FNSKU: </span>
                            {product.marketplaceData?.fnSku + '\n'}
                            <br />
                            <span className='font-bold'>Seller SKU: </span>
                            {product.marketplaceData?.sellerSku + '\n'}
                            <br />
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {totalItemQuantity?.[lineItem.listingId] -
                            (itemCountInBoxes?.[lineItem.listingId] || 0)}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <input
                            type='number'
                            value={lineItem?.quantityInBox}
                            onChange={(e) =>
                              updateBoxItems(
                                boxItems.map((item, lIndex) => {
                                  if (index === lIndex)
                                    return {
                                      ...item,
                                      quantityInBox:
                                        (e.target.value && parseInt(e.target.value)) || null,
                                    };
                                  return item;
                                }),
                              )
                            }
                            className='block w-[100px] rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className='flex gap-2 mt-8'>
              <button
                onClick={() => setSelectedNav(fulfillmentDetailNavigation[0].name)}
                className='rounded-md bg-hopstack-blue-600 flex gap-2 px-4 py-2 h-fit text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
              >
                <ArrowLeftIcon className='w-4 h-4 my-auto' />
                Go Back
              </button>
              <button
                onClick={() => {
                  setBoxes(
                    boxes.map((b, bIndex) => {
                      if (bIndex === selectedBox)
                        return {
                          ...b,
                          items: boxItems
                            .filter((bItem) => bItem.quantityInBox > 0)
                            .map((item) => {
                              return { listingId: item.listingId, quantity: item?.quantityInBox };
                            }),
                        };
                      return b;
                    }),
                  );
                  setSelectedNav(fulfillmentDetailNavigation[0].name);
                }}
                className='bg-hopstack-blue-700 flex gap-2 rounded-lg text-white py-2 text-sm font-semibold px-4 hover:opacity-80'
              >
                <div className=''>Add to box</div>
              </button>
            </div>
          </div>
        )}
      </SlideOver>
    </div>
  );
};

export default FulfillmentDetail;
