import React, { useContext, useEffect, useState } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import Cookies from 'universal-cookie';
import BaseContext from '../common/BaseContext';
import { sellerPartnerRegions } from '../common/utils';
import { Client } from '../common/types';
import { IntegrationConfig } from './AddIntegration';
import Dropdown from '../common/Dropdown';
import { DateTime } from 'luxon';

interface AmazonIntegrationProps {
  merchants: Client[];
  selectedIntegration: IntegrationConfig;
  setSelectedIntegration: React.Dispatch<React.SetStateAction<IntegrationConfig | null>>;
}
interface CountryInfo {
  country: string;
  countryCode: string;
  marketplaceId: string;
  sellerUrl: string;
}

interface IntegrationData {
  merchantId: string;
  integrationName: string;
  region: string;
  organizationId: string;
  connections: {
    connectionName: string;
    status: string;
    details: {
      marketplaceId: string;
    };
  }[];
}

const AmazonIntegration: React.FC<AmazonIntegrationProps> = ({
  merchants,
  selectedIntegration,
  setSelectedIntegration,
}) => {
  const cookies = new Cookies(null, { path: '/' });
  const appBaseUrl = process.env.REACT_APP_BASE_URL;
  const baseAuthPath = `/apps/authorize/consent?application_id=amzn1.sp.solution.2fa956a1-7cb7-48e4-9137-f8f11a3ea610&state=stateexample&redirect_uri=${appBaseUrl}/oauth/sp-api`;
  const sellerCountries: CountryInfo[] = sellerPartnerRegions.map((s) => s.countries)?.flat();
  const [authLink, setAuthLink] = useState(`https://sellercentral.amazon.com/${baseAuthPath}`);
  const [selectedMarketplaces] = useState<number[]>([0]);
  const [client, setClient] = useState(merchants[0]);
  const { organization } = useContext(BaseContext);
  const [country, setCountry] = useState(sellerCountries[0]);
  const currentDate = DateTime.now();
  const oneHourLater = currentDate.plus({ hours: 1 });

  useEffect(() => {
    const integrationData: IntegrationData = {
      merchantId: client?.merchantId,
      integrationName: 'Amazon',
      region: 'na',
      organizationId: organization?.organizationId,
      connections: selectedMarketplaces.map(() => {
        return {
          connectionName: country.countryCode,
          status: 'enabled',
          details: {
            marketplaceId: country.marketplaceId,
          },
        };
      }),
    };
    cookies.set('integration_data', integrationData, {
      path: '/',
      expires: oneHourLater.toJSDate(),
    });
  }, [selectedMarketplaces, country, merchants, client, organization]);

  useEffect(() => {
    setAuthLink(`${country.sellerUrl}${baseAuthPath}`);
  }, [selectedMarketplaces, country]);
  return (
    <div>
      <img
        width={200}
        height={200}
        className='-ml-4'
        src={selectedIntegration.icon}
        alt='Integration icon'
      />

      <label className='block text-[15px] font-medium text-left leading-6 text-gray-900'>
        Select Client
      </label>
      <p className='text-[12px] text-gray-500'>
        Select the client for whom you would like to enable this connection
      </p>

      <Dropdown<Client>
        items={merchants}
        selectedItem={client}
        setSelectedItem={setClient}
        getItemId={(item) => item.merchantId}
        getItemDisplayValue={(item) => item.name}
        placeholder='Select a merchant'
      />

      <label className='block text-[15px] font-medium text-left leading-6 text-gray-900 mt-6'>
        Select home marketplace
      </label>
      <p className='text-[12px] text-gray-500'>
        Choose the first marketplace that you enrolled in FBA
      </p>
      <Dropdown<CountryInfo>
        items={sellerCountries}
        selectedItem={country}
        setSelectedItem={setCountry}
        getItemId={(item) => item.marketplaceId}
        getItemDisplayValue={(item) => item.country}
        placeholder='Select marketplace'
      />

      <div className='flex mt-4 gap-2'>
        <button
          onClick={() => setSelectedIntegration(null)}
          className='rounded-md bg-hopstack-blue-500 flex gap-2 px-4 py-2 h-fit text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
        >
          <ArrowLeftIcon className='w-4 h-4 my-auto' />
          Go Back
        </button>

        {merchants.length > 0 ? (
          <a
            href={authLink}
            aria-disabled
            className='rounded-md bg-hopstack-blue-700 flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-700/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
          >
            Authorize
          </a>
        ) : (
          <button
            disabled
            className='rounded-md bg-gray-400 cursor-not-allowed flex gap-2 p-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
          >
            Cannot authorize without client
          </button>
        )}
      </div>
    </div>
  );
};

export default AmazonIntegration;
