import React, { useContext } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';

import { Link } from 'react-router-dom';
import BaseContext from '../common/BaseContext';

interface StepperProps {
  hasWarehouse: boolean;
  hasClient: boolean;
  hasIntegration: boolean;
  hasOrder: boolean;
  hasInventory: boolean;
  hasUsers: boolean;
}
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Stepper(props: StepperProps) {
  const { organization, orgRole } = useContext(BaseContext);
  const { hasWarehouse, hasClient, hasIntegration, hasOrder, hasInventory, hasUsers } = props;
  const getSteps = () => {
    if (organization) {
      if (orgRole === 'admin') {
        return [
          {
            name: 'Create your first order',
            description: 'Create your first outbound shipment to Amazon',
            href: `/${organization.organizationId}/outbound`,
            status: hasOrder ? 'complete' : 'current',
          },
        ];
      } else if (orgRole === 'client') {
        return [
          {
            name: 'Add a connection',
            description:
              'Establish a connection between your marketplaces and our platform to enable products and order synchronisation',
            href: `/${organization.organizationId}/connections`,
            status: hasIntegration ? 'complete' : 'current',
          },
          {
            name: 'Add inventory',
            description: `Add inventory by <a href="/${organization.organizationId}/inventory" class="hover:underline">creating initial stock through adjustments</a> OR <a href="/${organization.organizationId}/inbound" class="hover:underline">creating an inbound order</a>`,
            href: `/${organization.organizationId}/inventory`,
            status: hasIntegration && hasInventory ? 'complete' : 'current',
          },
          {
            name: 'Create your first order',
            description: 'Create your first outbound shipment to Amazon',
            href: `/${organization.organizationId}/outbound`,
            status: hasIntegration && hasInventory && hasOrder ? 'complete' : 'current',
          },
        ];
      } else {
        return [
          {
            name: 'Create an organization',
            description: 'This is the name of your business',
            href: '/',
            status: organization ? 'complete' : 'current',
          },
          {
            name: 'Create a warehouse',
            description:
              'This is the location from which you perform fulfilment. We collect this information to populate the ship from address for your couriers',
            href: `/${organization.organizationId}/organizations`,
            status: organization && hasWarehouse ? 'complete' : 'current',
          },
          {
            name: 'Add additional users',
            description: 'Add other people from your business who work on orders',
            href: `/${organization.organizationId}/organizations`,
            status: hasUsers ? 'complete' : 'current',
          },
          {
            name: 'Add your first client',
            description: 'Add the clients that you service through your business',
            href: `/${organization.organizationId}/clients`,
            status: organization && hasWarehouse && hasClient ? 'complete' : 'current',
          },
          {
            name: 'Add a connection',
            description:
              'Establish a connection between your marketplaces and our platform to enable products and order synchronisation',
            href: `/${organization.organizationId}/connections`,
            status:
              organization && hasWarehouse && hasClient && hasIntegration ? 'complete' : 'current',
          },
          {
            name: 'Add inventory',
            description: `Add inventory by <a href="/${organization.organizationId}/inventory" class="hover:underline">creating initial stock through adjustments</a> OR <a href="/${organization.organizationId}/inbound" class="hover:underline">creating an inbound order</a>`,
            href: `/${organization.organizationId}/inventory`,
            status:
              organization && hasWarehouse && hasClient && hasIntegration && hasInventory
                ? 'complete'
                : 'current',
          },
          {
            name: 'Create your first order',
            description: 'Create your first outbound shipment to Amazon',
            href: `/${organization.organizationId}/outbound`,
            status:
              organization &&
              hasWarehouse &&
              hasClient &&
              hasIntegration &&
              hasInventory &&
              hasOrder
                ? 'complete'
                : 'current',
          },
        ];
      }
    }
  };
  const steps = getSteps();
  return (
    <nav aria-label='Progress'>
      <ol role='list' className='overflow-hidden'>
        {organization &&
          steps.map((step, stepIdx) => (
            <li
              key={step.name}
              className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}
            >
              {step.status === 'complete' ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className='absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-hopstack-blue-600'
                      aria-hidden='true'
                    />
                  ) : null}
                  <Link to={step.href} className='group relative flex items-start'>
                    <span className='flex h-9 items-center'>
                      <span className='relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-hopstack-blue-600 group-hover:bg-hopstack-blue-800'>
                        <CheckIcon className='h-5 w-5 text-white' aria-hidden='true' />
                      </span>
                    </span>
                    <span className='ml-4 flex min-w-0 flex-col'>
                      <span className='text-sm font-medium'>{step.name}</span>
                      <span
                        className='text-sm text-gray-500'
                        dangerouslySetInnerHTML={{ __html: step.description }}
                      ></span>
                    </span>
                  </Link>
                </>
              ) : step.status === 'current' ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className='absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300'
                      aria-hidden='true'
                    />
                  ) : null}
                  <Link
                    to={step.href}
                    className='group relative flex items-start'
                    aria-current='step'
                  >
                    <span className='flex h-9 items-center' aria-hidden='true'>
                      <span className='relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-hopstack-blue-600 bg-white'>
                        <span className='h-2.5 w-2.5 rounded-full bg-hopstack-blue-600' />
                      </span>
                    </span>
                    <span className='ml-4 flex min-w-0 flex-col'>
                      <span className='text-sm font-medium text-hopstack-blue-600'>
                        {step.name}
                      </span>
                      <span
                        className='text-sm text-gray-500'
                        dangerouslySetInnerHTML={{ __html: step.description }}
                      ></span>
                    </span>
                  </Link>
                </>
              ) : (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className='absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300'
                      aria-hidden='true'
                    />
                  ) : null}
                  <Link to={step.href} className='group relative flex items-start'>
                    <span className='flex h-9 items-center' aria-hidden='true'>
                      <span className='relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400'>
                        <span className='h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300' />
                        {/* <XMarkIcon className='text-gray-300 m-auto h-8 w-8 mr-1 mb-2'/> */}
                      </span>
                    </span>
                    <span className='ml-4 flex min-w-0 flex-col'>
                      <span className='text-sm font-medium text-gray-500'>{step.name}</span>
                      <span
                        className='text-sm text-gray-500'
                        dangerouslySetInnerHTML={{ __html: step.description }}
                      ></span>
                    </span>
                  </Link>
                </>
              )}
            </li>
          ))}
      </ol>
    </nav>
  );
}
