import React, { useContext, useEffect, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import SlideOver from '../common/SlideOver';
import useRequest from '../../hooks/useRequest';
import BaseContext from '../common/BaseContext';
import Dropdown from '../common/Dropdown';
import { Spinner } from '../common/Spinner';

interface Location {
  id: string;
  name: string;
  address: {
    address1: string;
    address2: string | null;
    city: string;
    provinceCode: string;
    zip: string;
    countryCode: string;
  };
  isActive: boolean;
  hasActiveInventory: boolean;
  fulfillsOnlineOrders: boolean;
}

interface ShopifyConfigurationProps {
  isOpen: boolean;
  onClose: () => void;
  fetchIntegrations: (urlParams) => void;
  integrationId: string;
}

const ShopifyConfiguration: React.FC<ShopifyConfigurationProps> = ({
  isOpen,
  onClose,
  fetchIntegrations,
  integrationId,
}) => {
  const { setLoading } = useContext(BaseContext);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoadingLocations, setIsLoadingLocations] = useState(true);

  const { executeRequest: fetchShopifyLocations, data: locations } = useRequest<Location[]>(
    '/api/v1/integrations/shopify/{integrationId}/locations',
    [],
  );

  const { executeRequest: updateShopifyLocation } = useRequest<Location>(
    '/api/v1/integrations/shopify/{integrationId}/locations',
    null,
  );

  useEffect(() => {
    if (isOpen) {
      fetchLocations();
    }
  }, [isOpen]);

  const fetchLocations = async () => {
    setIsLoadingLocations(true);
    try {
      const response = await fetchShopifyLocations({
        queryParams: {},
        urlParams: { integrationId },
      });
      if (!response.success) {
        throw new Error('Failed to fetch locations');
      }
      setIsLoadingLocations(false);
    } catch (error) {
      console.error('Error fetching locations:', error);
      setError('Failed to fetch locations. Please try again.');
      setIsLoadingLocations(false);
    }
  };

  const handleSubmit = async () => {
    if (!selectedLocation) {
      setError('Please select a location');
      return;
    }

    setLoading(true);
    try {
      const response = await updateShopifyLocation(
        {
          queryParams: {},
          urlParams: { integrationId },
        },
        {
          method: 'PUT',
          body: JSON.stringify(selectedLocation),
        },
      );

      if (!response.success) {
        throw new Error('Failed to update location');
      }

      fetchIntegrations({
        integrationId,
      });
      onClose();
    } catch (error) {
      console.error('Error updating location:', error);
      setError('Failed to update location. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setSelectedLocation(null);
    setError(null);
    onClose();
  };

  return (
    <SlideOver open={isOpen} setOpen={handleClose}>
      <div className='h-full flex flex-col'>
        <div className='flex justify-between items-center px-4 border-gray-200 relative'>
          <h2 className='text-lg font-medium text-gray-900'>Configure Shopify Location</h2>
          <button
            onClick={handleClose}
            className='text-gray-400 hover:text-gray-500 absolute right-0 top-0 -mt-6'
            aria-label='Close'
          >
            <XMarkIcon className='h-6 w-6' strokeWidth={120} />
          </button>
        </div>

        <div className='flex-1 overflow-y-auto px-4'>
          <p className='text-sm text-gray-500 mb-4'>
            Please select the Shopify location you want to use for this integration.
          </p>

          <div className='space-y-6'>
            <div>
              <label htmlFor='location' className='block text-sm font-medium text-gray-700 mb-1'>
                Shopify Location
              </label>

              {isLoadingLocations ? (
                <div className='inline-flex m-auto gap-4'>
                  <Spinner />
                  <p>Loading locations...</p>
                </div>
              ) : (
                <Dropdown<Location>
                  items={locations}
                  selectedItem={selectedLocation}
                  setSelectedItem={setSelectedLocation}
                  getItemId={(item) => item.id}
                  getItemDisplayValue={(item) => item.name}
                  placeholder='Select a location'
                />
              )}
            </div>

            {error && <p className='text-red-500 text-sm'>{error}</p>}
          </div>
        </div>

        <div className='flex justify-between items-center px-4 py-4 border-gray-200'>
          <button onClick={handleClose} className='text-hopstack-blue-600 hover:underline'>
            Cancel
          </button>
          <button
            type='button'
            className='inline-flex justify-center rounded-md border border-transparent bg-hopstack-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-hopstack-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-hopstack-blue-500 focus-visible:ring-offset-2'
            onClick={handleSubmit}
            disabled={isLoadingLocations || !selectedLocation}
          >
            Submit
            <ChevronRightIcon className='ml-2 h-4 w-4' />
          </button>
        </div>
      </div>
    </SlideOver>
  );
};

export default ShopifyConfiguration;
