import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import BaseContext from '../components/common/BaseContext';
import { Integration } from '../components/common/types';
import { ShopifyLocationSelector } from '../components/OAuth/ShopifyLocationSelector';
import useRequest from '../hooks/useRequest';

const ShopifyAuthRedirection = () => {
  const cookies = new Cookies(null, { path: '/' });
  const { setShowNotification, setLoading } = useContext(BaseContext);
  const integrationData = cookies.get('integration_data');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isIntegrationAdded = useRef(false);
  const { executeRequest: addIntegration } = useRequest<Integration>('/api/v1/integrations');
  const [integrationId, setIntegrationId] = useState('');

  useEffect(() => {
    const addIntegrationAsync = async () => {
      try {
        setLoading(true);
        if (
          !searchParams.get('accessTokenKey') ||
          !searchParams.get('shop') ||
          searchParams.get('shop') !== integrationData?.connections?.[0].details?.shop
        ) {
          setShowNotification({
            show: true,
            type: 'failure',
            content: 'Unable to add integration, please try again.',
          });
          navigate(`/${integrationData.organizationId}/connections`);
        }
        if (!isIntegrationAdded.current) {
          const response = await addIntegration(
            { queryParams: {}, urlParams: {} },
            {
              method: 'POST',
              body: JSON.stringify({
                merchantId: integrationData?.merchantId,
                integrationName: integrationData?.integrationName,
                credentials: {
                  accessToken: searchParams.get('accessTokenKey'),
                  storeName: searchParams.get('shop'),
                },
                connections: integrationData?.connections,
              }),
            },
          );
          if (response.success) {
            setIntegrationId(response.data.integrationId);
          } else if (!response.success && response.errorCode === 'ERR:INT:003') {
            navigate(`/${integrationData.organizationId}/connections`);
          }
          isIntegrationAdded.current = true;
        }
      } catch (error) {
        console.log(error);
        setShowNotification({
          show: true,
          type: 'failure',
          content: error.message || 'An error occurred',
        });
        navigate(`/${integrationData.organizationId}/connections`);
      } finally {
        setLoading(false);
      }
    };

    addIntegrationAsync();
  }, [integrationData?.organizationId]);

  return (
    <>
      {integrationId ? (
        <ShopifyLocationSelector
          integrationId={integrationId}
          organizationId={integrationData.organizationId}
        />
      ) : (
        <>Adding integration</>
      )}
    </>
  );
};

export default ShopifyAuthRedirection;
