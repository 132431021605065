import React, { useContext, useEffect, useState } from 'react';

import useFetch from '../../hooks/useFetch';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useForm } from 'react-hook-form';
import { OnboardingStep } from '../../pages/Onboarding';
import _ from 'lodash';
import { Address, Organization } from '../common/types';
import BaseContext from '../common/BaseContext';
import { capitalize, countries } from '../common/utils';
import { SignOutButton, useUser } from '@clerk/clerk-react';
import useAddressValidator from '../../hooks/useAddressValidator';
import Modal from '../common/Modal';
// import { Input, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { DateTime } from 'luxon';
import { allCountries, CountryData } from 'country-region-data';
import SearchableListbox from '../common/SearchableListbox';

interface Props {
  orgData: Organization;
  setOrgData?: any;
  setOnboardingStep?: any;
  setEditMode?: any;
}

export default function CreateOrganization(props: Props) {
  const { orgData, setOrgData } = props;
  const [timezone, setTimezone] = useState<string>(DateTime.local().zoneName);
  const timezones = (Intl as any).supportedValuesOf('timeZone');
  const [mobileNumber, setMobileNumber] = useState(null);

  const { user } = useUser();

  // const [country, setCountry] = useState(orgData.billingAddress.country || null)

  // const [state, setState] = useState<any | null>(null)
  const [addressValidationError, setAddressValidationError] = useState<string | null>(null);
  const [correctedAddress, setCorrectedAddress] = useState<Address | null>(null);
  const [isAddressConfirmationModalOpen, setIsAddressConfirmationModalOpen] =
    useState<boolean>(false);
  const validateAddress = useAddressValidator();

  const {
    watch,
    reset,
    resetField,
    register,
    formState: { errors },
    handleSubmit, 
    setError,
    clearErrors,
    setValue,
  } = useForm<Organization>({
    defaultValues: {
      email: user?.primaryEmailAddress?.emailAddress,
    },
  });
  const formData = watch();
  const isUS = formData.billingAddress?.country?.code === 'US';
  const authenticatedFetch = useFetch();
  const [selectedCountry,setSelectedCountry] = useState("United States");
  const [regionsOfSelectedCountry,setRegionsOfSelectedCountry] =useState([]);
  const [selectedRegion , setSelectedRegion] = useState(null);
    useEffect(()=>
    { 
      if (formData && formData.billingAddress && formData.billingAddress.country) {
  
        // Only set the selected country if the name is defined
        if (formData.billingAddress.country.name) {
          setSelectedCountry(formData.billingAddress.country.name);
          setSelectedRegion(formData.billingAddress.state);
        }

        if (formData.billingAddress.country.name=="United States")
        {
          setValue('billingAddress.country', {
            name: "United States",
            code: "US",
          })
        }
      }
      },[formData]);

    function getRegionsByCountry(countryName: string): { name: string; abbreviation: string }[] | undefined {
      // Find the country data based on the country name
      const countryData: CountryData | undefined = allCountries.find(
          (country) => country[0] === countryName
      );
  
      // If country data is found, return the list of regions (states) with names and abbreviations
      if (countryData) {
          const regions = countryData[2]; // regions are the third element in the tuple
          return regions.map((region) => ({
              name: region[0],         // Region name
              abbreviation: region[1], // Region abbreviation
          }));
      }
  
      // If country data is not found, return undefined
      return undefined;
  }
  
  
  useEffect(() => {

    const regions=getRegionsByCountry(selectedCountry);
    setRegionsOfSelectedCountry(regions);


  },[selectedCountry]);

  useEffect(() => {
    if (orgData) {
      orgData.organizationId === undefined
        ? resetField('email', { defaultValue: user?.primaryEmailAddress?.emailAddress })
        : reset({
            ...orgData,
            billingAddress: {
              ...orgData.billingAddress,
              zipCode: `${orgData.billingAddress.zipCode}${
                (orgData.billingAddress.zipCodeSuffix &&
                  `-${orgData.billingAddress.zipCodeSuffix}`) ||
                ''
              }`,
            },
          });
    }
    if (orgData?.mobileNumber?.countryCode) {
      setMobileNumber(orgData?.mobileNumber);
    }
    if (orgData.timezone) {
      setTimezone(orgData.timezone);
    }
  }, [orgData, user]);

  const { setLoading, setShowNotification, setOrganization } = useContext(BaseContext);

  function createOrganization(org: Organization) {
    window.scrollTo(0, 0);
    setLoading(true);

    if (org.organizationId) {
      const updatedOrgData = _.pick(org, [
        'name',
        'email',
        'mobileNumber',
        'billingAddress',
        'timezone',
      ]);

      authenticatedFetch(`/api/v1/organizations/${org.organizationId}`, {
        method: 'PUT',
        body: JSON.stringify({ ...updatedOrgData }),
      }).then((res) => {
        setLoading(false);
        if (res.success === false) {
          setShowNotification({
            show: true,
            type: 'failure',
            content: capitalize(res.errors[0]?.message) || res.message,
          });
        } else {
          setShowNotification({
            show: true,
            type: 'success',
            content: 'Organization updated successfully!',
          });

          if (props.orgData) {
            setOrganization(res.data);
            if (props.setEditMode) {
              props.setEditMode(false);
            }
          }
          // props.setOnboardingStep(OnboardingStep.createWarehouse)
        }
      });
    } else {
      authenticatedFetch('/api/v1/organizations', {
        method: 'POST',
        body: JSON.stringify({ ...org }),
      }).then((res) => {
        setLoading(false);
        if (res.success === false) {
          setShowNotification({
            show: true,
            type: 'failure',
            content: res.message || res.errors[0]?.message,
          });
        } else {
          setShowNotification({
            show: true,
            type: 'success',
            content: 'Organization created successfully!',
          });
          setOrganization(res.data);

          setOrgData(res.data);
          props.setOnboardingStep(OnboardingStep.createWarehouse);
        }
      });
    }
  }

// Example usage
  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        const updatedAddress = {
          ...data.billingAddress,
          zipCode: data.billingAddress.zipCode.split('-')[0],
          zipCodeSuffix: data.billingAddress.zipCode.split('-')[1],
        };

        if (updatedAddress.country.code === 'US') {
          try {
            setAddressValidationError(null);
            setLoading(true);
            const resp = await validateAddress(data.billingAddress);

            setLoading(false);
            if (resp.suggestedAction === 'FIX') {
              setAddressValidationError('Incorrect address please fix and try again');
              return;
            } else if (resp.suggestedAction === 'ADD_SUBPREMISES') {
              setAddressValidationError('Specifiy the sub premises');
              return;
            } else if (resp.suggestedAction === 'CONFIRM') {
              setIsAddressConfirmationModalOpen(true);
              setCorrectedAddress(resp.suggestedAddress);
              return;
            }
          } catch (error) {
            setLoading(false);
            console.log(error);
          }
        }
        createOrganization({
          ...data,
          mobileNumber: mobileNumber,
          timezone,
          billingAddress: {
            ...updatedAddress,
            line2: updatedAddress.line2 === '' ? undefined : updatedAddress.line2,
            email: data.email,
          },
        });
      })}
    >
      <div className='space-y-12'>
        <div className='border-b border-gray-900/10 pb-5'>
          {/* <h1 className='font-bold text[20px]'>Onboarding Step 1</h1> */}
          <h1 className='text-[35px] font-semibold leading-7 text-gray-900 mt-8'>Create Organization</h1>
          <p className='text-[14px] w-1/2 pr-4 mt-2'>
            Please enter your business details here. We will use this to create your organization
            within the platform.
          </p>
        </div>

        <div className='border-b border-gray-900/10 pb-12 relative'>
          <div className='mt-10 grid grid-cols-1 gap-x-0 gap-y-8 sm:grid-cols-12 w-[900px] px-2'>
            <div className='sm:col-span-4'>
              <label
                htmlFor='first-name'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Organization name*
              </label>
              <div className='mt-2'>
                <input
                  {...register('name', { required: 'Name is required' })}
                  aria-invalid={errors.name ? 'true' : 'false'}
                  className='block w-3/4 rounded-lg border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                />
                {errors.name?.type === 'required' && (
                  <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                    {errors.name?.message.toString()}
                  </p>
                )}
              </div>
            </div>

            <div className='sm:col-span-4'>
              <label
                htmlFor='last-name'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Email address*
              </label>
              <div className='mt-2'>
                <input
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Entered value does not match email format',
                    },
                  })}
                  autoComplete='email'
                  className='block w-3/4 rounded-lg border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                />
                {errors.email && (
                  <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                    {errors.email?.message.toString()}
                  </p>
                )}
              </div>
            </div>

            <div className='sm:col-span-4'>
              <label
                htmlFor='phone-number'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Organization Phone Number*
              </label>
              <div className='mt-2'>
                <PhoneInput
                  country={'us'}
                  containerStyle={{
                    width: '100%',
                    maxWidth: '230px',
                    borderRadius: '8px',  // Consistent rounding on all corners
                    border: '1px solid #D1D5DB',
                  }}
                  inputStyle={{
                    width: '100%',
                    borderRadius: '8px',  // Consistent rounding on all corners
                    padding: '8px 16px',
                    paddingLeft: '45px',
                    boxSizing: 'border-box',
                  }}
                  enableSearch={true}
                  value={`${mobileNumber?.countryCode + mobileNumber?.number}`}
                  onChange={(value, data: any) => {
                    setError('mobileNumber', {
                      type: 'custom',
                      message: '',
                    });
                    setMobileNumber({
                      countryCode: data.dialCode,
                      number: value.slice(data.dialCode.length),
                    });
                  }}
                />
              </div>
              {errors.mobileNumber && (
                <p className='text-red-500 text-xs mt-1'>
                  {errors.mobileNumber?.message.toString()}
                </p>
              )}
            </div>

            <div className='sm:col-span-4'>
              <label className='block text-sm font-medium leading-6 text-gray-900'>
                Billing Address 1*
              </label>
              <div className='mt-2'>
                <input
                  {...register('billingAddress.line1', {
                    required: 'Billing Address Line 1 is required',
                  })}
                  className='block w-3/4 rounded-lg border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                />
                {errors.billingAddress?.line1 && (
                  <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                    {errors.billingAddress?.line1?.message.toString()}
                  </p>
                )}
              </div>
            </div>

            <div className='sm:col-span-4'>
              <label className='block text-sm font-medium leading-6 text-gray-900'>
                Billing Address 2
              </label>
              <div className='mt-2'>
                <input
                  {...register('billingAddress.line2')}
                  className='block w-3/4 rounded-lg border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                />
              </div>
            </div>

            <div className='sm:col-span-4'>
              <div className='hidden bg-green-400'></div>
              <label className='block text-sm font-medium leading-6 text-gray-900'>Timezone*</label>
              <div className='mt-2'>
                <SearchableListbox
                  onChange={(zone: { value: string; label: string }) => {
                    setTimezone(zone.value);
                  }}
                  options={timezones.map((item: string) => ({ value: item, label: item }))}
                  classNames={{
                    control: (state) => {
                      if (state.isFocused)
                        return ' !ring-2 !outline-hopstack-blue-600 !ring-inset !ring-hopstack-blue-600';
                    },
                  }}
                  className='w-[230px] borderRadius-[16px] rounded-lg border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6' 
                  placeholder={timezone}
                />
                {errors.timezone && (
                  <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                    {errors.timezone?.message.toString()}
                  </p>
                )}
              </div>
            </div>


            <div className='sm:col-span-4'>
              <div className='hidden bg-green-400'></div>
              <label
                htmlFor='country'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Country*
              </label>
        
              <div className='mt-2'>
                <SearchableListbox
                onChange={(selectedOption :  {value : string, label : string}) => {
                  setSelectedCountry(selectedOption.value);
                  setSelectedRegion('Select a state');
                  setValue('billingAddress.state',"");
                  setError('billingAddress.country', {
                    type: 'custom',
                    message: '',
                  });
      
                  if (selectedOption) {
                    setValue('billingAddress.country', {
                      name: selectedOption.label,
                      code: selectedOption.value,
                    });

                  } 
                }}
                  options={countries
                    .map(country => ({
                      value: country.code,
                      label: country.name,
                    }))
                    .sort((a, b) => {
                      if (a.value === 'US' || a.value === 'CA') return -1;
                      if (b.value === 'US' || b.value === 'CA') return 1;
                      return 0;
                    })
                  }
                  classNames={{
                    control: (state) => {
                      if (state.isFocused) {
                        return ' !ring-2 !outline-hopstack-blue-600 !ring-inset !ring-hopstack-blue-600';
                      }
                    },
                  }}
                  className='w-[222px] borderRadius-[16px] rounded-lg border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                  placeholder={selectedCountry}
                />
                {errors.billingAddress?.country && (
                  <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                    {errors.billingAddress?.country?.message.toString()}
                  </p>
                )}
              </div>
           </div>

           <div className='sm:col-span-4'>
            <label htmlFor='region' className='block text-sm font-medium leading-6 text-gray-900'>
              State / Province*
            </label>
            <div className='mt-2'>
              {regionsOfSelectedCountry ? (
                <SearchableListbox
                  onChange={(event: { value : string; label : string }) => {
                    setValue('billingAddress.state', event.value);
                  }}
                  options={regionsOfSelectedCountry.map((region) => ({
                    value: region.abbreviation,
                    label: region.name,
                  }))}
                  classNames={{
                    control: (state) => {
                      if (state.isFocused) {
                        return ' !ring-2 !outline-hopstack-blue-600 !ring-inset !ring-hopstack-blue-600';
                      }
                    },
                  }}
                  className='w-[222px] rounded-lg border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                  placeholder={selectedRegion}
                />
              ) : (
                <input
                  {...register('billingAddress.state', {
                    required: 'Billing Address State is required',
                  })}
                  className='block w-3/4 rounded-lg border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                />
              )}
              {errors.billingAddress?.state && (
                <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                  {errors.billingAddress?.state?.message.toString()}
                </p>
              )}
            </div>
          </div>


            <div className='sm:col-span-4 sm:col-start-1'>
              <label htmlFor='city' className='block text-sm font-medium leading-6 text-gray-900'>
                City*
              </label>
              <div className='mt-2'>
                <input
                  {...register('billingAddress.city', {
                    required: 'Billing Address City is required',
                  })}
                  className='block w-3/4 rounded-lg border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                />
                {errors.billingAddress?.city && (
                  <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                    {errors.billingAddress?.city?.message.toString()}
                  </p>
                )}
              </div>
            </div>

            <div className='sm:col-span-4'>
              <label className='block text-sm font-medium leading-6 text-gray-900'>Zip*</label>
              <div className='mt-2'>
                <input
                  {...register('billingAddress.zipCode', {
                    required: 'Billing Address Zip is required',
                  })}
                  className='block w-3/4 rounded-lg border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                />
                {errors.billingAddress?.zipCode && (
                  <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                    {errors.billingAddress?.zipCode?.message.toString()}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-6 flex items-center justify-start gap-x-6'>
        {addressValidationError && (
          <p className='text-red-500 text-[12px] opacity-80' role='alert'>
            {addressValidationError}
          </p>
        )}
      </div>
      <div className='mt-6 flex items-center justify-start gap-x-6'>
        <input
          type='submit'
          value={orgData?.organizationId ? 'Save' : 'Next'}
          onClick={() => {
            clearErrors();
            if (isUS && !formData?.billingAddress?.state) {
              setError('billingAddress.state', { type: 'custom', message: 'State is Required' });
            }

            if (!mobileNumber || !mobileNumber?.number) {
              setError('mobileNumber', { type: 'custom', message: 'Phone Number is Required' });
            }

            if (!formData?.billingAddress?.country || !formData?.billingAddress?.country?.name) {
              setError('billingAddress.country', {
                type: 'custom',
                message: 'Country is Required',
              });
            }
          }}
          className='rounded-md cursor-pointer bg-hopstack-blue-600 flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
        ></input>
          <SignOutButton>
            <button className="rounded-md bg-red-500 flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600">
              Sign Out
            </button>
          </SignOutButton>
      </div>
      <Modal isOpen={isAddressConfirmationModalOpen} setIsOpen={setIsAddressConfirmationModalOpen}>
        <div className='w-[500px]'>
          <h2 className='text-left text-[20px]'>Please confirm the updated address</h2>
          <div className='block text-sm font-medium text-left leading-6 mt-4 text-gray-900'>
            <div>
              {correctedAddress?.line1}, {correctedAddress?.line2}
            </div>
            <div>
              {correctedAddress?.city},{' '}
              {((correctedAddress?.state as any)?.name as string) || correctedAddress?.state}
            </div>
            <div>
              {correctedAddress?.country?.code} - {correctedAddress?.zipCode}
            </div>
          </div>
          <div className='flex gap-2 mt-8'>
            <button
              onClick={() => {
                const updatedOrg = {
                  ...formData,
                  billingAddress: correctedAddress,
                };
                reset(updatedOrg);
                // setOrgData(updatedOrg)
                // resetFormData(updatedOrg)
                setIsAddressConfirmationModalOpen(false);
              }}
              className='rounded-md bg-hopstack-blue-600 flex gap-2 px-4 py-2 h-fit text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
            >
              Accept the Address
            </button>
            <button
              onClick={() => {
                setIsAddressConfirmationModalOpen(false);
              }}
              className='bg-[#1B3886] flex gap-2 rounded-lg text-white py-2 text-sm font-semibold px-4 hover:opacity-80'
            >
              Edit address manually
            </button>
          </div>
        </div>
      </Modal>
    </form>
  );
}
