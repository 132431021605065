import { Client } from '../common/types';

export enum InboundOrderStatus {
  UNPROCESSED = 'unprocessed',
  PROCESSING = 'processing',
  ON_HOLD = 'on-hold',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  PAUSED = 'paused',
}

export enum ListingStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ARCHIVED = 'archived',
}

interface ServiceCost {
  value: number;
  currency: string;
}

interface Service {
  listingId: string;
  serviceName: string;
  serviceCost: ServiceCost;
}

interface Hazmat {
  aspect: string;
  value: string;
  marketplace_id: string;
}

interface MarketplaceData {
  fnSKu: string;
  asin: string;
  hazmat: Hazmat[];
  fulfillmentType: string;
}

interface Product {
  listingId: string;
  expectedQuantity: number;
  receivedQuantity: number;
  merchantId: string;
  integrationId: string;
  integrationConnectionId: string;
  integrationConnectionName: string;
  name: string;
  description: string;
  images: string[];
  upc: string[];
  ean: string[];
  searchTerms: string[];
  status: ListingStatus;
  isBundle: boolean;
  marketplaceData: MarketplaceData;
  isDeleted: boolean;
  deletedAt: string;
  bundleItems: any[];
  marketplaceListings: any[];
  createdAt: string;
  updatedAt: string;
}

export interface InboundOrder {
  inboundOrderId: string;
  inboundOrderDisplayId: string;
  merchantId: string;
  merchant: Client;
  warehouseId: string;
  locationId: string;
  userId: string;
  status: InboundOrderStatus;
  orderDate: string;
  expectedDeliveryDate: string;
  actualDeliveryDate: string;
  products: Product[];
  isDeleted: boolean;
  deletedAt: string;
  services: Service[];
  createdAt: string;
  updatedAt: string;
}
