import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import SlideOver from '../components/common/SlideOver';
import useFetch from '../hooks/useFetch';
import BaseContext from '../components/common/BaseContext';
import {
  ArrowLeftIcon,
  MagnifyingGlassIcon,
  MinusIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
  ExclamationTriangleIcon,
  ArrowPathIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { Dialog, Disclosure } from '@headlessui/react';
import { Client, Connection, Integration, Product, Warehouse } from '../components/common/types';
import Modal from '../components/common/Modal';
import Button from '../components/common/Button';
import OrdersTable from '../components/Outbound/OrdersTable';
import useRequest, { RequestParams } from '../hooks/useRequest';
import OutboundFilter from '../components/Outbound/OutboundFilter';
import Dropdown from '../components/common/Dropdown';
import SearchableDropdown from '../components/common/SearchableDropdown';
import { getMarketplace, MARKETPLACE_DATA } from '../utils/ConnectionConfig';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { Spinner } from '../components/common/Spinner';
import { debounce } from 'lodash';

// Interface for label type options
interface TransportTypeOption {
  name: string;
  value: string;
}

// Const array of label type options
const TRANSPORT_TYPE_OPTIONS: TransportTypeOption[] = [
  { name: 'Normal', value: 'normal' },
  { name: '2D Barcode', value: '2d_barcode' },
];
enum OutboundStatus {
  DEFAULT,
  CANNOT_PROCEED,
  CAN_PROCEED,
}

type DisplayProductType = 'displayProducts' | 'eligible' | 'ineligible';

type ShipmentPlan = {
  ShipmentId: string;
  DestinationFulfillmentCenterId: string;
  ShipToAddress: Address;
  LabelPrepType: string;
  Items: Item[];
  EstimatedBoxContentsFee: Fee;
};

type Address = {
  Name: string;
  AddressLine1: string;
  City: string;
  StateOrProvinceCode: string;
  CountryCode: string;
  PostalCode: string;
};

type Item = {
  SellerSKU: string;
  FulfillmentNetworkSKU: string;
  Quantity: number;
  listing: Listing;
};

type Listing = {
  listingId: string;
  merchantId: string;
  integrationId: string;
  integrationName: string;
  integrationConnectionId: string;
  integrationConnectionName: string;
  name: string;
  description: string;
  images: string[];
  upc: string[];
  ean: string[];
  searchTerms: string[];
  status: string;
  isBundle: boolean;
  marketplaceData: MarketplaceData;
  isListedOnMarketplace: boolean;
  isDeleted: boolean;
  deletedAt: string;
  bundleItems: any[];
  createdAt: string;
  updatedAt: string;
};

type MarketplaceData = {
  fnSku: string;
  marketplaceId: string;
  asin: string;
  sellerSku: string;
  hazmat: string;
  fulfillmentType: string;
  itemCondition: string;
};

type Fee = {
  TotalUnits: number;
  FeePerUnit: {
    CurrencyCode: string;
    Value: number;
  };
  TotalFee: {
    CurrencyCode: string;
    Value: number;
  };
};

const Outbound = () => {
  const [open, setOpen] = useState(false);
  const authenticatedFetch = useFetch();
  const { setLoading, organization, setShowNotification } = useContext(BaseContext);
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [searchText, setSearchText] = useState('');

  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse>();
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState<Client>();
  const [selectedIntegration, setSelectedIntegration] = useState<Integration>();
  const [selectedIntegrationConnection, setSelectedIntegrationConnection] = useState<Connection>();
  const [orders, setOrders] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [productPage, setProductPage] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [selectedMerchants, setSelectedMerchants] = useState([]);
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [displayProducts, setDisplayProducts] = useState([]);
  const [outboundStatus, setOutboundStatus] = useState(OutboundStatus.DEFAULT);
  const [hasMore, setHasMore] = useState(false);
  const [orderSearchText, setOrderSearchText] = useState('');
  const [currentStep, setCurrentStep] = useState('eligibility');
  const [selectedShipmentPlans, setSelectedShipmentPlans] = useState<ShipmentPlan[]>([]);
  const tableRef = useRef(null);
  const [transportType, setTransportType] = useState<TransportTypeOption>(
    TRANSPORT_TYPE_OPTIONS[0],
  );
  const pageRef = useRef(0);
  const fetchedPagesRef = useRef(new Set<number>());
  const pendingRequestsRef = useRef<AbortController[]>([]);

  const { executeRequest: fetchOrdersData, pending: isOrderFetching } = useRequest(
    `/api/v1/outbound-orders`,
    [],
  );
  const {
    executeRequest: fetchShipmentPlansData,
    data: shipmentPlans,
    isRequestExecuted: isShipmentPlansFetched,
  } = useRequest('/api/v1/outbound-orders/shipment-plans', []);

  useEffect(() => {
    if (organization?.organizationId) {
      authenticatedFetch(
        '/api/v1/warehouses?' +
          new URLSearchParams({
            'filter[organizationId]': organization?.organizationId,
          }),
      ).then((res) => {
        setSelectedWarehouse(res.data[0]);
        setWarehouses(res.data);
      });

      authenticatedFetch(
        `/api/v1/merchants?filter[organizationId]=${organization?.organizationId}`,
      ).then((res) => {
        if (res.success) {
          setMerchants(res.data);
          setSelectedMerchant(res.data[0]);
        }
      });
    }
  }, [organization]);

  useEffect(() => {
    if (outboundStatus === OutboundStatus.CAN_PROCEED)
      setOutboundStatus(OutboundStatus.CANNOT_PROCEED);
  }, [selectedProducts]);

  const getParams = useCallback((): RequestParams => {
    const params: RequestParams = {
      queryParams: { include: 'merchant' },
      urlParams: {},
    };

    if (selectedMerchants.length > 0) {
      params.queryParams['filter[merchantId]'] = selectedMerchants
        .map((merchant) => merchant.merchantId)
        .join(',');
    } else {
      params.queryParams['filter[merchantId]'] = merchants
        .map((merchant) => merchant.merchantId)
        .join(',');
    }

    params.queryParams['filter[warehouseId]'] = warehouses
      .map((warehouse) => warehouse.warehouseId)
      .join(',');

    if (orderSearchText.length > 0) {
      params.queryParams.search = orderSearchText;
    }

    if (selectedStatuses.length > 0) {
      params.queryParams['filter[status]'] = selectedStatuses.map((i) => i.value).join(',');
    }

    return params;
  }, [selectedMerchants, selectedStatuses, orderSearchText, merchants, warehouses]);

  const fetchOrders = useCallback(
    debounce(async (page: number) => {
      if (fetchedPagesRef.current.has(page)) return;
      if (!(merchants.length && warehouses.length)) return;

      // Create a new AbortController for this fetch
      const controller = new AbortController();
      const { signal } = controller;

      try {
        // If it's the initial fetch (page 0), cancel all pending requests
        if (page === 0) {
          setOrders([]);
          // Cancel all pending requests
          pendingRequestsRef.current.forEach((controller) => controller.abort());
          pendingRequestsRef.current = [];
        }

        // Add this controller to the list of pending requests
        pendingRequestsRef.current.push(controller);

        const params = getParams();
        params.queryParams = {
          ...params.queryParams,
          limit: '50',
          page: (page + 1).toString(),
        };

        const ordersDataResponse = await fetchOrdersData(params, { signal });

        if (ordersDataResponse.success) {
          setOrders((prevOrders) => {
            const newOrders = ordersDataResponse.data.filter(
              (order: any) => !prevOrders.some((p) => p.outboundOrderId === order.outboundOrderId),
            );
            return [...prevOrders, ...newOrders];
          });

          setHasMore(ordersDataResponse.data.length > 0);
          fetchedPagesRef.current.add(page);
          pageRef.current = page + 1;
        }

        return ordersDataResponse;
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Error fetching orders:', error);
        }
      } finally {
        // Remove this controller from the list of pending requests
        pendingRequestsRef.current = pendingRequestsRef.current.filter((c) => c !== controller);
      }
    }, 300),
    [getParams, fetchOrdersData, merchants, warehouses],
  );

  useEffect(() => {
    if (merchants && selectedMerchant) {
      authenticatedFetch(
        `/api/v1/listings?` +
          new URLSearchParams({
            search: searchText,
            'filter[merchantId]': selectedMerchant?.merchantId,
            'filter[warehouseId]': warehouses.map((w) => w.warehouseId).join(','),
            include: 'inventory',
          }),
      ).then((res) => {
        if (res.success) {
          setProducts(res.data);
        }
      });
    }
  }, [searchText, merchants, selectedMerchant]);

  useEffect(() => {
    if (organization && merchants && selectedMerchant) {
      authenticatedFetch(
        '/api/v1/integrations?' +
          new URLSearchParams({
            // 'organizationId': organization.organizationId,
            'filter[merchantId]': selectedMerchant?.merchantId,
          }),
      ).then((res) => {
        setLoading(false);
        if (res.success) {
          setIntegrations(res.data);
          // setSelectedIntegration(res.data[0]?.integrationName)
        }
      });
    }
  }, [organization, selectedMerchant, merchants]);

  const handleFetchOrderAsync = async () => {
    try {
      fetchedPagesRef.current = new Set<number>();
      await fetchOrders(0);
    } catch (error) {
      console.log('Error fetching orders: ', error);
    }
  };

  useEffect(() => {
    if (warehouses.length && merchants.length) {
      pageRef.current = 0;
      handleFetchOrderAsync();
    }
  }, [selectedMerchants, selectedStatuses, orderSearchText, merchants, warehouses]);

  const addOrder = () => {
    if (selectedShipmentPlans.length < 1) {
      setShowNotification({ show: true, type: 'failure', content: 'Please select shipment plans' });
      return;
    }

    const groupedDisplayProducts = {
      eligible: [],
      ineligible: [],
    };
    displayProducts?.forEach((displayProduct) => {
      const isEligible = eligibility?.[displayProduct.product.listingId].isEligible || false;
      if (isEligible) {
        groupedDisplayProducts['eligible'].push(displayProduct);
      } else {
        groupedDisplayProducts['ineligible'].push(displayProduct);
      }
    });

    const createOrderPromises = [];

    ['eligible', 'ineligible'].forEach((displayProductType) => {
      if (groupedDisplayProducts[displayProductType].length > 0) {
        createOrderPromises.push(
          authenticatedFetch(`/api/v1/outbound-orders`, {
            method: 'POST',
            body: JSON.stringify({
              integrationId: selectedIntegration.integrationId,
              integrationConnectionId: selectedIntegrationConnection
                ? selectedIntegrationConnection.connectionId
                : selectedIntegration?.connections[0].connectionId,
              warehouseId: selectedWarehouse.warehouseId,
              merchantId: selectedMerchant.merchantId,
              transportType: transportType.value,
              inboundShipmentPlans: selectedShipmentPlans.map((shipment) => {
                return {
                  ...shipment,
                  Items: shipment.Items.map((item) => {
                    return { ...item, listing: undefined };
                  }),
                };
              }),
              lineItems: selectedShipmentPlans.flatMap((shipment) =>
                shipment.Items.map((item) => ({
                  listingId: item.listing.listingId,
                  quantity: item.Quantity,
                })),
              ),
            }),
          }),
        );
      }
    });

    Promise.all(createOrderPromises).then(([firstOrderResponse, secondOrderResponse]) => {
      if (firstOrderResponse.success && (!secondOrderResponse || secondOrderResponse.success)) {
        setOpen(false);
        handleFetchOrderAsync();
        setOutboundStatus(OutboundStatus.DEFAULT);
        setProductPage(false);
        setSelectedProducts([]);
        setLoading(false);
        setShowNotification({ show: true, type: 'success', content: 'Order created successfully' });
      } else if (
        !firstOrderResponse.success ||
        (secondOrderResponse && !secondOrderResponse.success)
      ) {
        const errorMessages = [];
        const updatedDisplayProducts = [];
        if (!firstOrderResponse.success) {
          errorMessages.push(firstOrderResponse.message || firstOrderResponse.errors[0]?.message);
          updatedDisplayProducts.push(...groupedDisplayProducts['eligible']);
        }
        if (secondOrderResponse && !secondOrderResponse.success) {
          errorMessages.push(secondOrderResponse.message || secondOrderResponse.errors[0]?.message);
          updatedDisplayProducts.push(...groupedDisplayProducts['ineligible']);
        }
        setDisplayProducts(updatedDisplayProducts);

        setShowNotification({
          show: true,
          type: 'failure',
          content: errorMessages.join(','),
        });
      }
    });
  };

  const renderAddress = (address) => (
    <>
      {address.City}, {address.CountryCode}
    </>
  );

  const handleSelect = (shipment) => {
    setSelectedShipmentPlans((prev) =>
      prev.includes(shipment) ? prev.filter((id) => id !== shipment) : [...prev, shipment],
    );
  };

  const handleSelectAll = () => {
    if (selectedShipmentPlans.length === shipmentPlans?.length) {
      setSelectedShipmentPlans([]);
    } else {
      setSelectedShipmentPlans(shipmentPlans);
    }
  };

  const getTotalQuantity = (items: Item[]): number => {
    return items.reduce((total, item) => total + item.Quantity, 0);
  };

  const applyFilter = () => {
    setIsFiltersModalOpen(false);
  };

  const statuses = ['pending', 'failed', 'created', 'completed'];

  const [eligibility, setEligibility] = useState(null);

  const runEligibilityCheck = () => {
    setLoading(true);
    setDisplayProducts((prevDisplayProducts) => {
      return prevDisplayProducts.map((p) => {
        return { ...p, quantity: p.quantity || 0 };
      });
    });
    authenticatedFetch(`/api/v1/outbound-orders/eligibility`, {
      method: 'POST',
      body: JSON.stringify({
        integrationId: selectedIntegration.integrationId,
        integrationConnectionId: selectedIntegration?.connections[0].connectionId,
        merchantId: selectedMerchant.merchantId,
        warehouseId: selectedWarehouse.warehouseId,
        lineItems: displayProducts.map((p) => {
          return {
            listingId: p.product.listingId,
            quantity: p.quantity || 0,
          };
        }),
      }),
    }).then((res) => {
      setLoading(false);
      if (res.success) {
        handleFetchOrderAsync();
        setEligibility(res.data.itemsEligibility);
        setOutboundStatus(OutboundStatus.CAN_PROCEED);
      } else {
        setShowNotification({
          show: true,
          type: 'failure',
          content: res.message || res.errors[0]?.message,
        });
      }
    });
  };

  const fetchShipmentPlans = async () => {
    setLoading(true);
    try {
      setDisplayProducts((prevDisplayProducts) => {
        return prevDisplayProducts.map((p) => ({
          ...p,
          quantity: p.quantity || 0,
        }));
      });

      const shipmentPlanResponse = await fetchShipmentPlansData(
        {
          queryParams: {},
          urlParams: {},
        },
        {
          method: 'POST',
          body: JSON.stringify({
            integrationId: selectedIntegration.integrationId,
            integrationConnectionId: selectedIntegration?.connections[0].connectionId,
            merchantId: selectedMerchant.merchantId,
            warehouseId: selectedWarehouse.warehouseId,
            lineItems: displayProducts.map((p) => ({
              listingId: p.product.listingId,
              quantity: p.quantity || 0,
            })),
          }),
        },
      );

      if (!shipmentPlanResponse.success) {
        throw new Error(
          shipmentPlanResponse.message ||
            shipmentPlanResponse.errors[0]?.message ||
            'Failed to fetch shipment plans',
        );
      }

      // Handle successful response here if needed
    } catch (error) {
      console.error('Error fetching shipment plans:', error);
      setShowNotification({
        show: true,
        type: 'failure',
        content: error.message || 'An unexpected error occurred while fetching shipment plans',
      });
    } finally {
      setLoading(false);
    }
  };

  const groupedDisplayProducts: Record<
    DisplayProductType,
    {
      displayProductIndex: number;
      data: {
        product: Product;
        quantity: number;
      };
    }[]
  > = {
    displayProducts: [],
    eligible: [],
    ineligible: [],
  };

  displayProducts?.forEach((displayProduct, index) => {
    const hasEligibility = eligibility?.[displayProduct.product.listingId];

    if (!hasEligibility) {
      groupedDisplayProducts['displayProducts'].push({
        displayProductIndex: index,
        data: displayProduct,
      });
    } else {
      const isEligible = eligibility?.[displayProduct.product.listingId].isEligible || false;
      if (isEligible) {
        groupedDisplayProducts['eligible'].push({
          displayProductIndex: index,
          data: displayProduct,
        });
      } else {
        groupedDisplayProducts['ineligible'].push({
          displayProductIndex: index,
          data: displayProduct,
        });
      }
    }
  });

  const renderProductOption = (product, isSelected) => {
    return (
      <>
        <div
          className={`${isSelected ? 'bg-green-50' : 'bg-white hover:bg-gray-50'} p-3 truncate`}
          key={product.listingId}
        >
          <div className='flex gap-2'>
            <img
              className='rounded object-scale-down h-10'
              src={product?.images[0]}
              height={40}
              width={40}
            />
            <div>
              <div className='truncate'>{product.name}</div>
              <div className='flex mt-2 gap-2'>
                {product?.marketplaceData?.sellerSku && (
                  <div>
                    <span className='font-bold'>SKU:</span> {product.marketplaceData.sellerSku}
                  </div>
                )}
                {product?.marketplaceData?.asin && (
                  <div>
                    <span className='font-bold'>ASIN:</span> {product.marketplaceData.asin}
                  </div>
                )}
                {product?.marketplaceData?.fnSku && (
                  <div>
                    <span className='font-bold'>FNSKU:</span> {product.marketplaceData.fnSku}
                  </div>
                )}
                {product?.marketplaceData?.marketplaceId && (
                  <div>
                    <span className='font-bold'>Marketplace:</span>{' '}
                    {MARKETPLACE_DATA.find(
                      (marketplace) =>
                        marketplace.marketplaceId === product.marketplaceData.marketplaceId,
                    )?.countryCode || 'Unknown'}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const loadMoreOrders = useCallback(() => {
    if (!isOrderFetching && hasMore) {
      fetchOrders(pageRef.current);
    }
  }, [isOrderFetching, hasMore, fetchOrders]);

  return (
    <div className='py-4 px-8'>
      <div className='flex justify-between my-2'>
        <div>
          <h1 className='text-[#030229] text-[24px] font-bold'>Outbound Orders</h1>
          <p className='text-[14px]'>
            Manage all orders and inventory getting shipped from your warehouse
          </p>
        </div>
        <Button
          onClick={() => {
            setOpen(true);
          }}
        >
          <div>Create Shipment</div>
        </Button>
      </div>

      <div className='flex gap-4 my-4'>
        {merchants.length > 0 && statuses.length > 0 && (
          <OutboundFilter
            merchants={merchants.map((e) => ({ ...e, label: e.name, value: e.merchantId }))}
            statuses={statuses.map((e) => ({ label: e, value: e }))}
            onApply={(checkedMerchants: any, checkedStatus) => {
              setSelectedMerchants(checkedMerchants);
              setSelectedStatuses(checkedStatus);
            }}
            onReset={() => {}}
          />
        )}

        <div className='w-full'>
          <label htmlFor='search' className='sr-only'>
            Search
          </label>
          <div className='relative text-gray-400 focus-within:text-gray-600 border border-gray-300 shadow-sm w-full rounded-md'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
            </div>
            <input
              value={orderSearchText}
              onChange={(e) => setOrderSearchText(e.target.value)}
              id='search'
              className='block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900  focus:outline-none sm:text-sm sm:leading-6'
              placeholder='Search'
              type='search'
              name='search'
            />
          </div>
        </div>
      </div>
      <div className=' shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
        <OrdersTable
          fetchMoreData={loadMoreOrders}
          hasMore={hasMore}
          orders={orders}
          merchants={merchants}
          isFetching={isOrderFetching}
          handleOrderClick={() => {}}
        />
      </div>
      <SlideOver open={open} setOpen={setOpen} large={true}>
        <div className='flex items-start justify-between'>
          <Dialog.Title className='text-xl font-semibold leading-20 text-gray-900 ml-5'>
            {currentStep === 'eligibility' ? 'Create Shipment Plan' : 'Shipment Plan Preview '}
          </Dialog.Title>
          <div className='flex h-7'>
            <button
              type='button'
              className='relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-hopstack-blue-500 focus:ring-offset-2'
              onClick={() => setOpen(false)}
            >
              <span className='absolute -inset-2.5' />
              <span className='sr-only'>Close panel</span>
              <XMarkIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
        </div>

        {currentStep === 'eligibility' ? (
          <div>
            {/* Content for Create Shipment Plan */}
            {productPage ? (
              <div>
                <div>
                  <div className='w-full'>
                    <label
                      htmlFor='transportType'
                      className='block text-sm font-medium leading-6 mt-2 text-gray-900'
                    >
                      Transport Type
                    </label>

                    <Dropdown<TransportTypeOption>
                      items={TRANSPORT_TYPE_OPTIONS}
                      selectedItem={transportType}
                      setSelectedItem={setTransportType}
                      getItemDisplayValue={(item) => item.name}
                      getItemId={(item) => item.value}
                    />
                  </div>
                  <div className='mt-2'>
                    <SearchableDropdown<Product>
                      options={products}
                      onChange={() => {
                        setDisplayProducts(
                          selectedProducts.map((newProd) => {
                            const existingProduct = displayProducts.find(
                              (displayProd) => displayProd.product.listingId === newProd.listingId,
                            );
                            return {
                              product: newProd,
                              quantity: existingProduct ? existingProduct.quantity : 1,
                            };
                          }),
                        );
                      }}
                      getOptionLabel={(product) => product.name}
                      getOptionValue={(product) => product.listingId}
                      placeholder='Select products...'
                      renderOption={renderProductOption}
                      isMulti={true}
                      query={searchText}
                      setQuery={setSearchText}
                      selectedOptions={selectedProducts}
                      setSelectedOptions={setSelectedProducts}
                    />
                  </div>
                </div>

                {['displayProducts', 'eligible', 'ineligible'].map(
                  (displayProductType: DisplayProductType) => {
                    if (groupedDisplayProducts[displayProductType].length === 0) {
                      return '';
                    }
                    return (
                      <>
                        <div className='mt-10 font-bold'>
                          {displayProductType === 'displayProducts'
                            ? 'Added Products'
                            : displayProductType === 'eligible'
                              ? 'Products that can be shipped with Partnered Carriers'
                              : 'These products cannot be shipped with partnered carriers. You will still be able to create shipments on Amazon, but you may not be able to use partnered carriers to ship these shipments.'}
                        </div>
                        <div className='overflow-hidden mt-4 shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
                          <table className='min-w-full divide-y divide-gray-300'>
                            <thead className='bg-gray-50'>
                              <tr>
                                <td className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                                  Image
                                </td>
                                <td className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[40rem]'>
                                  Product
                                </td>
                                <td className='text-center text-sm font-semibold text-gray-900 w-[10rem]'>
                                  Available Qty.
                                </td>
                                <td className='py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6'>
                                  Quantity
                                </td>
                                <td className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                                  Comments
                                </td>
                                <td className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                                  Action
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {groupedDisplayProducts[displayProductType].map((p) => {
                                return (
                                  <tr
                                    className={
                                      displayProductType === 'eligible'
                                        ? 'bg-green-100'
                                        : displayProductType === 'ineligible'
                                          ? 'bg-[#FFE7E7]'
                                          : ''
                                    }
                                    key={p.displayProductIndex}
                                  >
                                    <td className='pl-5 my-3 rounded'>
                                      <img
                                        className=' object-scale-down h-28'
                                        src={p.data.product?.images[0]}
                                        height={100}
                                        width={100}
                                      />
                                    </td>
                                    <td className='py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                      <div>
                                        <div className='text-hopstack-blue-700 line-clamp-3'>
                                          {p.data.product.name}
                                        </div>
                                        <div className='flex mt-2 gap-2'>
                                          {p.data.product.marketplaceData.sellerSku && (
                                            <div>
                                              <span className='font-bold'>SKU:</span>{' '}
                                              {p.data.product.marketplaceData.sellerSku}
                                            </div>
                                          )}
                                          {p.data.product.marketplaceData.asin && (
                                            <div>
                                              <span className='font-bold'>ASIN:</span>{' '}
                                              {p.data.product.marketplaceData.asin}
                                            </div>
                                          )}
                                          {p.data.product.marketplaceData.fnSku && (
                                            <div>
                                              <span className='font-bold'>FNSKU:</span>{' '}
                                              {p.data.product.marketplaceData.fnSku}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td className='font-medium text-gray-900 text-center'>
                                      {p.data.product?.inventory.availableQuantity?.toFixed(0)}
                                    </td>
                                    <td className='py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                      <div className='flex gap-2'>
                                        <button
                                          onClick={() => {
                                            setDisplayProducts(
                                              displayProducts.map((dp, pIndex) => {
                                                if (pIndex === p.displayProductIndex)
                                                  return {
                                                    ...dp,
                                                    quantity: Math.max(
                                                      0,
                                                      parseInt(dp.quantity) - 1,
                                                    ),
                                                  };
                                                return dp;
                                              }),
                                            );
                                          }}
                                        >
                                          <MinusIcon className='w-4 h-4 text-[#1B3886]' />
                                        </button>
                                        <input
                                          step={1}
                                          min={0}
                                          onKeyDown={(evt) =>
                                            (evt.keyCode === 190 || evt.keyCode === 189) &&
                                            evt.preventDefault()
                                          }
                                          onChange={(e) => {
                                            setDisplayProducts(
                                              displayProducts.map((dp, pIndex) => {
                                                if (pIndex === p.displayProductIndex)
                                                  return {
                                                    ...dp,
                                                    quantity: Math.min(
                                                      p.data.product.inventory.availableQuantity,
                                                      parseInt(e.target.value),
                                                    ),
                                                  };
                                                return dp;
                                              }),
                                            );
                                          }}
                                          type='number'
                                          className='w-[100px] text-center py-2 border rounded-xl text-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                                          value={p.data.quantity}
                                          required
                                        />
                                        <button
                                          onClick={() => {
                                            setDisplayProducts(
                                              displayProducts.map((dp, pIndex) => {
                                                if (pIndex === p.displayProductIndex)
                                                  return {
                                                    ...dp,
                                                    quantity: Math.min(
                                                      p.data.product.inventory.availableQuantity,
                                                      parseInt(dp.quantity) + 1,
                                                    ),
                                                  };
                                                return dp;
                                              }),
                                            );
                                          }}
                                        >
                                          <PlusIcon className='w-4 h-4 text-[#1B3886]' />
                                        </button>
                                      </div>
                                    </td>
                                    <td className='py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                      {eligibility &&
                                      eligibility[p.data.product.listingId] &&
                                      eligibility[p.data.product.listingId].isEligible === false
                                        ? eligibility[p.data.product.listingId]
                                            .ineligibilityReasonList[0].message
                                        : ''}
                                    </td>
                                    <td className='py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                      <button
                                        className='mx-auto px-3'
                                        onClick={() => {
                                          setDisplayProducts(
                                            displayProducts.filter(
                                              (s) =>
                                                displayProducts[p.displayProductIndex].product
                                                  .listingId !== s.product.listingId,
                                            ),
                                          );

                                          setSelectedProducts(
                                            selectedProducts.filter(
                                              (s) =>
                                                displayProducts[p.displayProductIndex].product
                                                  .listingId !== s.listingId,
                                            ),
                                          );
                                        }}
                                      >
                                        <TrashIcon className='w-8 h-8 hover:text-red-600 text-red-400' />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    );
                  },
                )}
                <div className='flex gap-2 my-4'>
                  <button
                    onClick={() => setProductPage(false)}
                    className='rounded-md bg-hopstack-blue-600 flex gap-2 px-4 py-2 h-fit text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
                  >
                    <ArrowLeftIcon className='w-4 h-4 my-auto' />
                    Go Back
                  </button>
                  {outboundStatus === OutboundStatus.CAN_PROCEED ? (
                    <button
                      onClick={() => {
                        setCurrentStep('get-shipment-plan');
                        fetchShipmentPlans();
                      }}
                      className='rounded-md bg-[#1B3886] flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
                    >
                      Shipment Plan Preview
                    </button>
                  ) : (
                    <button
                      disabled={displayProducts.length === 0}
                      onClick={() => runEligibilityCheck()}
                      className='rounded-md bg-hopstack-blue-700 flex gap-2 hover:disabled:cursor-not-allowed px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
                    >
                      {outboundStatus === OutboundStatus.CANNOT_PROCEED
                        ? 'Re-Run Eligibility Check'
                        : 'Run Eligibility Check'}
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className='relative mt-6 flex-1'>
                {warehouses.length !== 1 && (
                  <div className='w-full'>
                    <label
                      htmlFor='warehouse'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      Warehouse Name
                    </label>
                    <Dropdown<Warehouse>
                      items={warehouses}
                      selectedItem={selectedWarehouse}
                      setSelectedItem={setSelectedWarehouse}
                      getItemDisplayValue={(item) => item.name}
                      getItemId={(item) => item.warehouseId}
                    />
                  </div>
                )}

                {merchants.length !== 1 && (
                  <div className='w-full'>
                    <label
                      htmlFor='Client'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      Client Name
                    </label>
                    <Dropdown<Client>
                      items={merchants}
                      selectedItem={selectedMerchant}
                      setSelectedItem={setSelectedMerchant}
                      getItemDisplayValue={(item) => item.name}
                      getItemId={(item) => item.merchantId}
                    />
                  </div>
                )}

                {integrations.length >= 1 && (
                  <div className='w-full'>
                    <label
                      htmlFor='Integration'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      Integrations
                    </label>
                    <Dropdown<Integration>
                      items={integrations}
                      selectedItem={selectedIntegration}
                      setSelectedItem={setSelectedIntegration}
                      getItemDisplayValue={(item) => item.integrationName}
                      getItemId={(item) => item.integrationId}
                      placeholder='Select Integration'
                    />
                  </div>
                )}

                {selectedIntegration && selectedIntegration.connections.length > 1 && (
                  <div className='w-full'>
                    <label
                      htmlFor='Integration'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      Marketplace
                    </label>
                    <Dropdown<Connection>
                      items={selectedIntegration.connections}
                      selectedItem={selectedIntegrationConnection}
                      setSelectedItem={setSelectedIntegrationConnection}
                      getItemDisplayValue={(item) =>
                        getMarketplace(item.details.marketplaceId).country
                      }
                      getItemId={(item) => item.connectionId}
                      placeholder='Select Integration'
                    />
                  </div>
                )}
                <button
                    onClick={() => {
                      if (integrations.length > 0) {
                        setProductPage(true);
                      } else {
                        setShowNotification({
                          show: true,
                          type: 'failure',
                          content: `${selectedMerchant.name} has no connections available. Please add a connection for ${selectedMerchant.name} to continue.`,
                        });
                      }
                    }}
                  className='rounded-md my-4 bg-hopstack-blue-700 flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
                >
                  Continue
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className='relative p-4'>
            {/* Warning Banner */}
            <div className='relative top-0 left-0 right-0 bg-yellow-100 text-yellow-800 border border-yellow-300 p-4 rounded-md flex items-center'>
              <div className='flex items-center'>
                <ExclamationTriangleIcon className='w-5 h-5 mr-2' />
                <span>
                  Rejecting or abandoning too many shipment plans can cause penalties to be incurred
                  on the associated Amazon account.
                </span>
              </div>
            </div>
            <div className='relative top-0 left-0 right-0 bg-blue-100 my-4 text-blue-800 border border-blue-300 p-4 rounded-md flex items-center'>
              <div className='flex items-center'>
                <InformationCircleIcon className='w-9 h-9 mr-2' />
                <span>
                  If you choose to confirm shipments that contain a mix of items that can and cannot
                  be shipped with <strong>Partnered Carriers</strong>, then the entire shipment will
                  need to be shipped with <strong>non-partnered carriers</strong>.
                </span>
              </div>
            </div>

            {/* Shipment Plan Preview */}
            {/* <div className="mt-4 bg-white p-4 rounded-md shadow-md"> */}
            <p className='text-sm text-gray-600 mt-2'>
              Based on your chosen products, Amazon has sent us the following shipment plan options.
              Please choose the shipments you would like to confirm. You can also get new options by
              clicking on Retry.
            </p>
            <button
              onClick={() => fetchShipmentPlans()}
              className='mt-4 rounded-md bg-white flex items-center gap-2 px-4 py-2 h-fit text-sm font-semibold text-[#0073bb] shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#0073bb]'
            >
              <ArrowPathIcon height={16} />
              Retry
            </button>
            <div className='relative overflow-hidden mt-4 shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
              <div className='overflow-x-auto' ref={tableRef}>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                        <input
                          type='checkbox'
                          checked={selectedShipmentPlans.length === shipmentPlans?.length}
                          onChange={handleSelectAll}
                          className='form-checkbox'
                        />
                      </th>
                      <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                        Destination
                      </th>
                      <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                        FC
                      </th>
                      <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                        Shipment ID
                      </th>
                      <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                        SKUs
                      </th>
                      <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                        Items
                      </th>
                      <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                        View Details
                      </th>
                    </tr>
                  </thead>
                  <tbody className=' bg-white divide-y divide-gray-200 '>
                    {!isShipmentPlansFetched ? (
                      <tr className=''>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className='mx-auto w-full flex justify-center py-7 pl-3'>
                          <Spinner />
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    ) : (
                      shipmentPlans?.map((shipment) => (
                        <Disclosure key={shipment.ShipmentId}>
                          {({ open }) => (
                            <>
                              <tr>
                                <td className='px-6 py-4 whitespace-nowrap'>
                                  <input
                                    type='checkbox'
                                    checked={selectedShipmentPlans.includes(shipment)}
                                    onChange={() => handleSelect(shipment)}
                                    className='form-checkbox'
                                  />
                                </td>
                                <td className='px-6 py-4 whitespace-nowrap'>
                                  {renderAddress(shipment.ShipToAddress)}
                                </td>
                                <td className='px-6 py-4 whitespace-nowrap'>
                                  {shipment.DestinationFulfillmentCenterId}
                                </td>
                                <td className='px-6 py-4 whitespace-nowrap'>
                                  {shipment.ShipmentId}
                                </td>
                                <td className='px-6 py-4 whitespace-nowrap'>
                                  {shipment?.Items?.length || 'N/A'}
                                </td>
                                <td className='px-6 py-4 whitespace-nowrap'>
                                  {getTotalQuantity(shipment.Items)}
                                </td>
                                <td className='px-6 py-4 whitespace-nowrap'>
                                  <Disclosure.Button className='text-gray-500 hover:text-gray-700 focus:outline-none'>
                                    {open ? <EyeSlashIcon height={20} /> : <EyeIcon height={20} />}
                                  </Disclosure.Button>
                                </td>
                              </tr>
                              <Disclosure.Panel as='tr'>
                                <td colSpan={7} className='p-2 rounded-lg bg-gray-50  '>
                                  <div className='bg-gray-50 max-h-[20rem] overflow-scroll '>
                                    <table className='min-w-full divide-y divide-gray-200'>
                                      <thead className='bg-hopstack-blue-100  h-8'>
                                        <tr>
                                          <th
                                            scope='col'
                                            className=' px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                                          >
                                            #
                                          </th>
                                          <th
                                            scope='col'
                                            className='px-4 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider'
                                          >
                                            Product Name
                                          </th>
                                          <th
                                            scope='col'
                                            className='px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                                          >
                                            Identifiers
                                          </th>
                                          <th
                                            scope='col'
                                            className=' px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                                          >
                                            Quantity
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className='bg-white divide-y divide-gray-200'>
                                        {shipment.Items.map((item, index) => (
                                          <tr
                                            key={item.FulfillmentNetworkSKU}
                                            className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                                          >
                                            <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                              {index + 1}
                                            </td>
                                            <td className='px-6 py-4 whitespace-nowrap'>
                                              <div className='flex items-center'>
                                                <div className='flex-shrink-0 h-10 w-10'>
                                                  <img
                                                    className='h-10 w-10 rounded-full object-scale-down'
                                                    src={item.listing?.images[0]}
                                                    alt=''
                                                  />
                                                </div>
                                                <div className='ml-4'>
                                                  <div className='text-sm font-medium w-[20rem] h-10 truncate text-gray-900 '>
                                                    {item.listing?.name}
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td className='px-6 py-4 whitespace-nowrap'>
                                              {item.listing?.marketplaceData?.asin && (
                                                <div className='text-sm text-gray-900'>
                                                  ASIN: {item.listing.marketplaceData.asin}
                                                </div>
                                              )}
                                              {item.listing?.marketplaceData?.fnsku && (
                                                <div className='text-sm text-gray-900'>
                                                  FNSKU: {item.listing.marketplaceData.fnsku}
                                                </div>
                                              )}
                                              {item.listing?.upc?.[0] && (
                                                <div className='text-sm text-gray-500'>
                                                  UPC: {item.listing.upc[0]}
                                                </div>
                                              )}
                                              {item.SellerSKU && (
                                                <div className='text-sm text-gray-500'>
                                                  SKU: {item.SellerSKU}
                                                </div>
                                              )}
                                            </td>
                                            <td className='px-6 py-4 whitespace-nowrap'>
                                              <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800'>
                                                {item.Quantity}
                                              </span>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='flex gap-2 my-4'>
              <button
                onClick={() => setCurrentStep('eligibility')}
                className='rounded-md bg-hopstack-blue-600 flex gap-2 px-4 py-2 h-fit text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-600/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
              >
                <ArrowLeftIcon className='w-4 h-4 my-auto' />
                Go Back
              </button>
              <button
                onClick={() => addOrder()}
                className='rounded-md bg-hopstack-blue-700 flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
              >
                Proceed with Shipment Confirmation
              </button>
            </div>
          </div>
        )}
      </SlideOver>
      <Modal isOpen={isFiltersModalOpen} setIsOpen={setIsFiltersModalOpen}>
        <div className='w-[500px]'>
          <h2 className='text-left text-[20px]'>Filters</h2>

          <button
            onClick={() => applyFilter()}
            className='rounded-md my-4 bg-hopstack-blue-700 flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
          >
            Apply
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Outbound;
